<template>
    <div class="container-fluid">
       <div class="row">
           <div class="col-lg-12">
               <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                   <div>
                       <h4 class="mb-3">{{title}}</h4>
                       <p class="mb-0">{{desc1}}<br>{{desc2}}<br>{{desc3}} ----> <a href="http://pf.kakao.com/_AxcWvxj" target="_blank"><u>쿠패스 전용 채널톡 바로가기</u></a></p>
                       <!-- <p class="mb-0">{{desc1}}<br>{{desc2}}&nbsp;&nbsp;&nbsp;&nbsp;<u v-b-modal.modal-3 variant="link" style="color:blue;">{{desc3}}</u></p>
                       <b-modal id="modal-3" size="xl" title="1688 주문서 업로드 방법" ok-title="Save Changes" cancel-title="Close">
                           <p>생성한 주문서를 <a href="https://www.1688.com" target="_blank"><u>1688</u></a>에 접속해서 아래의 순서로 업로드해주세요</p>
                           <img  style="max-width:100%;" :src="require('../../../assets/images/small/1688주문서업로드.png')">
                       </b-modal> -->
                   </div>
               </div>
               <!-- <div class="d-flex float-right"><button type="button" class="btn btn-primary mr-2" v-b-modal.modal-2>견적서</button></div> -->

                       <b-modal id="modal-2" size="xl" title="견적서" ok-only>
                           <div class="text-center">
                               <h3 class="mb-2">적용 회원등급 : {{$store.state.user.grade.toUpperCase()}}</h3>
                               <img style="max-width:100%;" :src="require('../../../assets/images/small/견적서.jpg')">
                           </div>

                       </b-modal>

               <tab-nav :tabs="true" id="myTab-1">
                   <tab-nav-items :active="true" id="req-tab" ariaControls="req" role="tab" :ariaSelected="true" title="구매대행요청" />
                   <tab-nav-items :active="false" id="list-tab" ariaControls="list" role="tab" :ariaSelected="false" title="구매대행요청내역" />
               </tab-nav>

               <tab-content id="myTabContent">
                   <tab-content-item :active="true" id="req" aria-labelled-by="req-tab">

                           <div class="row" v-if="showlist">
                               <div class="col-12">
                                   <div class="table-responsive">
                                        <tableList>
                                            <template v-slot:header>
                                               <tr class="ligth ligth-data">
                                                   <th>
                                                       <div class="checkbox d-inline-block">
                                                           <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allchecked" @change="allchecklist(allchecked)">
                                                           <label for="checkbox1" class="mb-0"></label>
                                                       </div>
                                                   </th>
                                                   <th>제품정보</th>
                                                   <th>상품매칭</th>
                                                   <th style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">구매링크</th>
                                                   <th>옵션1_중국어</th>
                                                   <th>옵션2_중국어</th>
                                                   <!-- <th>구매가격</th> -->
                                                   <th>구매재고</th>
                                                   <th>요청사항</th>
                                                   <th>구매요청수량</th>
                                                   <th>Actions</th>
                                               </tr>
                                            </template>
                                            <template v-slot:body>
                                               <tr v-for="(list,index) in lists" :key="index">
                                                   <td>
                                                       <div class="checkbox d-inline-block">
                                                           <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="list.checked">
                                                           <label for="checkbox2" class="mb-0"></label>
                                                       </div>
                                                   </td>

                                                   <td>
                                                       <div class="d-flex align-items-center">
                                                           <img :src="list.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image" v-if="list.등록이미지">
                                                           <div v-if="list.상품명">
                                                               {{list.상품명}}
                                                               <p class="mb-0"><small>{{list.SKUID ? list.SKUID : ''}}</small></p>
                                                               <p class="mb-0"><small>{{list.바코드}}</small></p>
                                                           </div>
                                                           <div v-else>
                                                               <input type="text" class="form-control" placeholder="상품명입력" v-model="상품명init" @change="addProductName(list)">
                                                           </div>
                                                       </div>
                                                   </td>
                                                   <td><div :class="matchclass(list.매칭상태)">{{list.매칭상태}}</div></td>
                                                   <td style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">
                                                       <div v-if="list.구매링크 !== undefined">
                                                           <a :href="list.구매링크" target="_blank">{{list.구매링크}}</a>
                                                           <div v-if="list.showspinner">
                                                               <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                                                           </div>
                                                       </div>
                                                       <div v-else>
                                                           <input type="text" class="form-control" placeholder="구매링크입력" @keypress.enter="matchoption1(index,list.구매링크init)" v-model="list.구매링크init" @change="matchoption1(index,list.구매링크init)">
                                                       </div>
                                                   </td>
                                                   <td>
                                                       <div v-if="list.옵션이미지 !== undefined">
                                                           <img :src="list.옵션이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                       </div>
                                                       <div v-if="list.옵션1_중국어 !== undefined">
                                                           {{list.옵션1_중국어}}
                                                       </div>
                                                       <div v-else>
                                                           <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret :popper-options="{ modifiers: { preventOverflow: { boundariesElement: 'viewport', padding: 8 } } }">
                                                               <template #button-content>
                                                               &#x1f50d;<span class="sr-only">Search</span>
                                                               </template>
                                                               <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션1_중국어" :key="i" @click="option1matching(a,list)" :style="{ maxHeight: '600px' }">
                                                                   <div class="d-flex align-items-center">
                                                                       <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                       <div>
                                                                           {{a.name}}
                                                                       </div>
                                                                   </div>
                                                               </b-dropdown-item>
                                                           </b-dropdown>
                                                       </div>
                                                   </td>
                                                   <td>
                                                       <div v-if="list.옵션2_중국어 !== undefined">
                                                           {{list.옵션2_중국어}}
                                                       </div>
                                                       <div v-else>
                                                           <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                                               <template #button-content>
                                                               &#x1f50d;<span class="sr-only">Search</span>
                                                               </template>
                                                               <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션2_중국어" :key="i" @click="option2matching(a,list)">
                                                                   <div class="d-flex align-items-center">
                                                                       <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                       <div>
                                                                           {{a.name}}
                                                                       </div>
                                                                   </div>
                                                               </b-dropdown-item>
                                                           </b-dropdown>
                                                       </div>
                                                   </td>
                                                   <!-- <td>
                                                       {{list.구매가격}}
                                                       <small>({{list.이전구매가격}})</small>
                                                   </td> -->
                                                   <td>{{list.구매재고}}</td>
                                                   <td>
                                                       <div :class="reqclass(list.요청사항)" v-b-modal="modalreq(index)">{{list.요청사항}}</div>
                                                       <b-modal :id="'modalreq' + index" size="xl" title="구매요청사항" @show="onModalShow(index, list)" ok-title="요청저장" cancel-title="취소" @ok="savereq($event,list,index)">
                                                           <div class="d-flex justify-content-end">
                                                               <b-button class="mr-2" variant="secondary" @click="cancelreq(index)">취소</b-button>
                                                               <b-button variant="primary" @click="savereq($event,list,index)">요청저장</b-button>
                                                           </div>
                                                           <div class="row">
                                                               <div class="col-md-12 mt-3">
                                                                   <h6>[ 출고유형 ]</h6>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="list.요청내역.poarrange">
                                                                       <label class="custom-control-label" for="customSwitch1">발주센터/입고예정일에 따라 박스 분류</label>
                                                                   </div>
                                                                   <span v-if="!list.요청내역.poarrange">※제품/발주 구분없이 적재되어 출고됩니다.</span>
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <div class="input-group" v-if="list.요청내역.poarrange">
                                                                           <input type="text" class="form-control" placeholder="발주서선택" aria-label="" v-model="list.selectpo" style="text-align:center;">
                                                                           <div class="input-group-append">
                                                                               <b-dropdown class="my-class" variant="primary" type="button" text="">
                                                                                   <b-dropdown-item @click="polistchange(b,list)" v-for="b,j in list.polists" :key="j">발주번호 : {{b.발주번호}} ({{b.발주센터}}/{{b.입고예정일}}) - {{b.sku수량}}개</b-dropdown-item>
                                                                               </b-dropdown>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio1" name="customRadio-3" class="custom-control-input" v-model="list.요청내역.exportType" value="pallet">
                                                                       <label class="custom-control-label" for="customRadio1">파렛트출고 (￦20000/pallet)</label>
                                                                   </div>
                                                                   <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio2" name="customRadio-3" class="custom-control-input" v-model="list.요청내역.exportType" value="box">
                                                                       <label class="custom-control-label" for="customRadio2">박스출고</label>
                                                                   </div>
                                                                   <span>※최대 박스 사이즈는 60cm(가로) x 40cm(세로) x 50cm(높이)이며, 상황에 따라 변경될 수 있습니다.</span>
                                                               </div>

                                                               <div class="col-md-12 mt-3">
                                                                   <h6>[ 구매요청수량 ]</h6>
                                                                   <div class="custom-control-inline">
                                                                       <input type="number" :min="0" class="form-control" placeholder="구매요청수량" aria-label="" v-model="list.구매요청수량" style="text-align:center;">
                                                                   </div>
                                                               </div>

                                                               <div class="col-md-12" v-if="list.구매요청수량 > list.발주수량 && list.발주수량 > 0 && list.요청내역.poarrange">
                                                                   <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio3" name="customRadio-13" class="custom-control-input" v-model="list.재고동봉" value=true>
                                                                       <label class="custom-control-label" for="customRadio3">나머지 수량 출고</label>
                                                                   </div>
                                                                   <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio4" name="customRadio-13" class="custom-control-input" v-model="list.재고동봉" value=false>
                                                                       <!-- <label class="custom-control-label" for="customRadio4">나머지 수량 재고보관 (￦{{cost.store}}/Pallet, 월)</label> -->
                                                                       <label class="custom-control-label" for="customRadio4">나머지 수량 재고보관 (￦300/CBM, 일)</label>
                                                                   </div>
                                                                   <span>※나머지 수량 출고 시 별도의 박스로 함께 출고됩니다.</span>
                                                               </div>


                                                               <div class="col-md-12 mt-3">
                                                                   <h6 v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">[ 판매구성수량 ]</h6>
                                                                   <div class="custom-control-inline">
                                                                       <input type="number" :min="0" class="form-control" placeholder="판매구성수량" aria-label="" v-model="list.판매구성수량" style="text-align:center;">
                                                                   </div>
                                                               </div>

                                                               <div class="col-md-12 mt-3 d-flex align-items-center justify-contents-start">
                                                                   <h6>[묶음구성여부(다른제품 합포장)]</h6>
                                                                   <div class="custom-control ml-3 custom-switch custom-control-inline d-flex align-items-center">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch111" v-model="list.묶음구성여부" @change="checktest(list.묶음구성여부)">
                                                                       <label class="custom-control-label" for="customSwitch111">&nbsp;</label>
                                                                       <input v-if="list.묶음구성여부 == true" type="number" :min="0" class="form-control ml-3" placeholder="종류갯수" aria-label="" v-model="list.묶음구성수량" style="text-align:center;" v-b-tooltip.bottom="'3종의 제품을 묶음 구성하는 경우 3으로 작성'">
                                                                       <input v-if="list.묶음구성여부" type="text" class="form-control ml-3" placeholder="묶음대상바코드번호" aria-label="" v-model="list.묶음대상바코드" style="text-align:center;" v-b-tooltip.bottom="'묶음구성할 대상의 바코드를 입력해 주세요.'">
                                                                   </div>
                                                               </div>

                                                               <div class="col-md-12 mt-3">
                                                                   <h6 v-b-tooltip.bottom="'통관 시 필요한 정보를 기입해주세요 ex. 신고영문명 : shoes, 신고가격(USD) : 3.5, HS-CODE : 6401.10-0000'">[ 통관정보 ]</h6>
                                                                   <div class="custom-control-inline">
                                                                       <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="list.신고영문명" style="text-align:center;" v-b-tooltip.bottom="'제품의 신고영문명을 간단히 작성해주세요.'">
                                                                   </div>
                                                                   <div class="custom-control-inline">
                                                                       <input type="number" :min="0" class="form-control" placeholder="신고가격(USD)" aria-label="" v-model="list.신고가격" style="text-align:center;" v-b-tooltip.bottom="'제품의 신고가격(USD)를 작성해주세요. 원/위안화는 달러로 환산해서 작성해주세요.'">
                                                                   </div>
                                                                   <div class="custom-control-inline">
                                                                       <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="list.HSCODE" style="text-align:center;" v-b-tooltip.bottom="'통관할 제품의 HS-CODE를 작성해주세요 ex : 6401.10-1000'">
                                                                   </div>
                                                                   <a href="https://www.bandtrass.or.kr/hsnavi.do?page=F&site=" target="_blank"><u>HS-CODE 검색</u></a>
                                                               </div>





                                                               <div class="col-md-12 mt-3">
                                                                   <h6>[ 원산지작업 ]</h6>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="list.요청내역.barcode" @change="labelling(list.요청내역)">
                                                                       <label class="custom-control-label" for="customSwitch2">바코드라벨(원산지,한글표시사항 포함) (￦{{cost.barcode}})</label>
                                                                   </div>
                                                                   <div class="custom-control custom-switch custom-control-inline" v-if="list.요청내역.barcode">
                                                                       <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="list.바코드" style="text-align:center;" >
                                                                   </div>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="list.요청내역.sticker">
                                                                       <label class="custom-control-label" for="customSwitch3">원산지스티커 (￦{{cost.sticker}})</label>
                                                                   </div>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="list.요청내역.stamp">
                                                                       <label class="custom-control-label" for="customSwitch4">원산지도장 (￦{{cost.stamp}})</label>
                                                                   </div>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch5" v-model="list.요청내역.tag">
                                                                       <label class="custom-control-label" for="customSwitch5">원산지택총 (￦{{cost.tag}})</label>
                                                                   </div>
                                                               </div>

                                                               <div class="col-md-12 mt-3">
                                                                   <h6>[ 검수 및 포장작업 ]</h6>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="list.요청내역.default" @change="qadefault(list.요청내역)">
                                                                       <label class="custom-control-label" for="customSwitch6">기본검수 (￦{{cost.default}})</label>
                                                                   </div>
                                                               </div>
                                                               <!-- <div class="col-md-12 mt-2">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch7" v-model="list.요청내역.opp">
                                                                       <label class="custom-control-label" for="customSwitch7">투명 OPP 봉투 (견적서 참조)</label>
                                                                   </div>
                                                               </div>
                                                               <div class="col-md-12 mt-2">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch8" v-model="list.요청내역.LDPE">
                                                                       <label class="custom-control-label" for="customSwitch8">불투명 LDPE 봉투 (견적서 참조)</label>
                                                                   </div>
                                                               </div>   -->
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="list.요청내역.airpacking">
                                                                       <label class="custom-control-label" for="customSwitch9">에어캡 포장 (실비)</label>
                                                                   </div>
                                                                   <p>※큰 부피의 제품(최대박스 사이즈의 1/5이상 사이즈)인 경우 검수 및 포장작업 별도 협의</p>
                                                               </div>

                                                               <div class="col-md-12 mt-3 d-flex align-items-center justify-contents-start">
                                                                   <h6>[한글표시사항]</h6>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="name-input">
                                                                       <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                           <label class="w-50 ml-0">제품명</label>
                                                                           <input type="text" class="form-control" placeholder="제품명" aria-label="" v-model="list.상품명" style="text-align:center;"  v-b-tooltip.left="'ex) 쿠플러스 예쁜접시, 10인치'">
                                                                       </div>
                                                                       <div class="warning2" style="font-weight: 500">* 쿠팡에 등록한 상품명 + 옵션명을 같이 입력해주세요</div>
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">수입원/판매원</label>
                                                                       <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="list.한글표시사항['수입원/판매원']" style="text-align:center;"  v-b-tooltip.left="'ex) 회사명'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">제조원</label>
                                                                       <input type="text" class="form-control" placeholder="제조원" aria-label="" v-model="list.한글표시사항.제조원" style="text-align:center;"  v-b-tooltip.left="'ex) 회사명OEM'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">제조국</label>
                                                                       <input type="text" class="form-control" placeholder="제조국" aria-label="" v-model="list.한글표시사항.제조국" style="text-align:center;"  v-b-tooltip.left="'ex) 중국'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">내용량</label>
                                                                       <input type="text" class="form-control" placeholder="내용량" aria-label="" v-model="list.한글표시사항.내용량" style="text-align:center;"  v-b-tooltip.left="'ex) 300ml'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">원료명및성분명</label>
                                                                       <input type="text" class="form-control" placeholder="원료명및성분명(재질)" aria-label="" v-model="list.한글표시사항['원료명및성분명(재질)']" style="text-align:center;"  v-b-tooltip.left="'ex) 도자기제'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">상품유형</label>
                                                                       <input type="text" class="form-control" placeholder="상품유형" aria-label="" v-model="list.한글표시사항.상품유형" style="text-align:center;"  v-b-tooltip.left="'ex) 주방용품'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">사용시주의사항</label>
                                                                       <input type="text" class="form-control" placeholder="사용시주의사항" aria-label="" v-model="list.한글표시사항.사용시주의사항" style="text-align:center;"  v-b-tooltip.left="'ex) 용도 이외에 사용금지'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">사용기준</label>
                                                                       <input type="text" class="form-control" placeholder="사용기준" aria-label="" v-model="list.한글표시사항.사용기준" style="text-align:center;"  v-b-tooltip.left="'ex) 14세 이상'">
                                                                   </div>
                                                               </div>

                                                               <div class="col-md-12 mt-3" style="display: flex">
                                                                   <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                                                   <div class="mt-3" style="margin-left: 8px; position: relative; top: 4px; color: #e83e8c">(예: 1688제품 겉박스 중국어 있는부분 작업 요청 내용, 실사, 사이즈, 무게, 센터 입고요청. OPP/LDPE/뽁뽁이 일반검수/정밀검수)</div>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="list.요청내역.reqcontent"></textarea>
                                                               </div>

                                                           </div>
                                                       </b-modal>

                                                   </td>
                                                   <td>{{list.구매요청수량}}</td>
                                                   <td>
                                                       <div class="d-flex align-items-center list-action">
                                                           <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="옵션재매칭" href="javascript:void(0)" @click="rematching(list,index)">
                                                               <i class="ri-pencil-line mr-0"></i>
                                                           </a>
                                                           <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset" href="javascript:void(0)" @click="reset(list)">
                                                               <i class="ri-eye-line mr-0"></i>
                                                           </a>
                                                           <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteitem(index)">
                                                               <i class="ri-delete-bin-line mr-0"></i>
                                                           </a>
                                                       </div>
                                                   </td>
                                               </tr>
                                            </template>
                                        </tableList>
                                   </div>
                               </div>
                               <div class="col-12 mb-0">
                                    <div class="d-flex justify-content-between form-control border-success h-100">
                                        <div class="d-flex justify-content-start text-center">
                                            <b-button @click="addlists" variant="outline-primary" class="text-center">+</b-button>
                                            <input type="text" class="form-control ml-4" placeholder="SKU ID (없으면 생략)" @keyup.enter="addlists" v-model="추가sku">
                                        </div>
                                        <button type="button" class="btn btn-primary" @click="savereqlist">현재상태저장</button>
                                    </div>
                               </div>
                               <div class="col-12 mb-4">
                                   <button type="button" class="btn btn-primary mr-2" @click="requestpurchase">구매대행요청</button>
                                   <button type="button" class="btn btn-danger mr-2" @click="saveproductdb(true)">상품DB에 저장</button>
                                   <!-- <button type="button" class="btn btn-primary mr-2" @click="savereqlist">임시저장</button> -->
                                   <button type="button" class="btn btn-secondary mr-2" @click="deletecheckitem">선택삭제</button>
                                   <button type="button" class="btn btn-success mr-2" v-b-modal.excelupload>대량주문</button>

                                   <b-modal size="sm" id="excelupload" scrollable title="대량 주문 업로드" hide-footer>
                                       <div class="row">
                                           <div class="col-md-12">
                                               <!-- <p><b style="color:red">1688을 통해 구매하지 않거나, 별도 재고로 관리하는 사입제품의 경우, 구매링크를 '사입'이라고 표시해 주세요</b></p>
                                               <p>신규상품, 발주상태 등 상품 내역에 변경사항이 있을 경우 서플라이어에 접속한 후 <u v-b-modal.modal-3>크롬확장프로그램</u>으로 SKU 리스트를 먼저 수집해 주세요</p> -->
                                               <p>[업로드 방법]</p>
                                               <p>1. 엑셀업로드 포맷 다운로드<br>
                                                   → <u>엑셀 파일에서 바코드/구매요청수량 업데이트</u> <br>
                                                   → 2. 업데이트된 엑셀 파일 업로드 순으로 진행해 주세요.</p>

                                           </div>
                                           <div class="col-md-12 mt-4">
                                               <div class="form-group">
                                                   <label>1. 엑셀업로드 포맷</label>
                                                   <div>
                                                   <button type="reset" @click="excelDownFunc" class="btn btn-primary mr-2">Download</button>
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="col-md-12">
                                               <div class="form-group">
                                                   <label  for="Code">2. 업데이트된 구매요청양식 업로드</label>
                                                   <div class="custom-file">
                                                   <input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                                   <label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
                                                   </div>
                                                   <div class="col-md-12" v-if="showuploadspinner">
                                                       <div>
                                                           <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </b-modal>

                                   <!-- <div class="custom-control custom-switch custom-control-inline ml-5">
                                       <input type="checkbox" class="custom-control-input" id="customSwitch11" v-model="checkstock">
                                       <label class="custom-control-label" for="customSwitch11" v-b-tooltip.right="'구매할 제품의 재고 및 옵션상태를 확인하면서 등록합니다.'">구매재고확인등록</label>
                                   </div>              -->
                               </div>
                           </div>



                       <!-- <div class="card d-none">
                           <div class="card-header">
                               <div class="custom-control custom-radio custom-control-inline">
                                   <input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="로켓배송">
                                   <label class="custom-control-label" for="customRadio15">로켓배송</label>
                               </div>
                               <div class="custom-control custom-radio custom-control-inline">
                                   <input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="제트배송">
                                   <label class="custom-control-label" for="customRadio16">제트배송</label>
                               </div>
                               <div class="custom-control custom-radio custom-control-inline">
                                   <input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="일반LCL">
                                   <label class="custom-control-label" for="customRadio17">일반LCL</label>
                               </div>
                           </div>
                           <div class="card-body">
                               <div class="row">


                                   <div class="col-md-12 mt-2">
                                       <p class="mb-0" @click="confirmexcelDownFunc"><u variant="link" style="color:blue;">구매대행 요청 양식 다운로드</u></p>
                                   </div>
                                   <div class="col-md-12 mt-2">
                                       <b-button variant="primary" v-b-modal.modal-scrollable>파일 업로드</b-button>

                                       <b-modal id="modal-scrollable" ok-title="동의" cancel-title="거부" scrollable title="구매대행 주의사항" @ok="fileupload()">
                                           <p class="my-4">
                                               1] 구매대행 신청시 주의 사항<br><br>

                                               1. 구매대행 신청 시 입력해주시는 견적사항에 맞게 선결제를
                                               한 후 구매를 진행하며 해당 금액이 변동되어 추가금액이 발생할
                                               경우 차이 금액이 기존 금액의 50%미만이라면 구매 진행 후
                                               무게 측정 시 해당 비용만큼 추가비용으로 결제요청을 드립니다.
                                               50%가 초과한 경우 제품의 추가비용만큼 추가로 결제를 진행해주셔야
                                               하며 추가 결제를 완료 후 구매작업을 진행합니다.<br><br>

                                               2. 구매대행 진행 시 제품 재고 부족 등의 이유로 판매자가 배송을
                                               진행하지 않거나 지연되는 경우 해당 제품의 금액만큼 환급되며
                                               이 경우 환급 시 수수료는 제외됩니다.<br><br>

                                               3. 환급 시 해당 주의 고시환율을 기준으로 환율이 적용
                                               되며 예치금으로 전환 됩니다.<br><br>

                                               4. 구매대행 신청서 작성시 양식의 내용이 정확하지 않을 경우에는
                                               구매대행이 취소될 수 있습니다.<br><br>

                                               5. 구매대행은 온라인 상품에 대한 구매만을 이야기 하기 때문에
                                               해당 제품의 정품 여부 파손 오염 여부 등에 대하여 보장을
                                               하지 않습니다.<br><br>

                                               6. 신청서 작성 후 선 결제를 진행하지 않고 7일 이상 초과한
                                               경우 신청서는 자동 폐기처리됩니다.<br><br>

                                               7. 신청서의 오기재로 인한 주문 오류는 쿠플러스 및 쿠플러스 제휴 배송대행지에서
                                               책임을 지지 않습니다.<br><br>

                                               8. 신청서에서 작성되는 모든 정보의 경우 세관신고서에 반영되는
                                               정보이기 때문에 사용자가 정확하게 입력해주셔야 합니다.
                                               허위 정보 작성  정보 미 or 오기재로 인해 발생하는 불이익에
                                               대해서는 쿠플러스 및 쿠플러스 제휴 배송대행지에서 책임지지 않습니다.<br><br>

                                               10. 수입이 금지된 제품(마약/위험물품 등)의 제품 구매 및
                                               배송으로 인해 발생하는 불이익에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서 어떠한
                                               책임도 지지 않습니다.<br><br>

                                               11. 제품의 운송장번호(트래킹 번호)당 하나의 그룹으로 묶여서
                                               진행됩니다. 추후 배송지시  컨펌 등의 확인 및 배송 요청에
                                               대하여 그룹으로 움직이며 그룹을 해지하고자 하시는 경우는
                                               카카오톡 채널 고객센터를 통해 해지 요청을 해주셔야 합니다.
                                               그룹 해지 요청시 추가비용이 발생하며 분리 난이도에 따라
                                               비용이 발생할 수 있습니다.<br><br>

                                               12. 타오바오/알리바바 등에서 주문한 제품이 고객님의 문제로
                                               판매자와 논쟁이 발생할 경우 쿠플러스 및 쿠플러스 제휴 배송대행지에서 조언 등은
                                               가능하지만 책임을 지고 문제를 해결해 드릴 수는 없습니다.
                                               단, 판매자의 문제이거나 저희의 실수로 인해서 발생한 문제의
                                               경우에는 구매대행 담당자가 책임지고 문제를 해결해 드리도록
                                               하겠습니다.<br><br>

                                               13. 제품의 실사는 제품의 최소 포장 단위를 기준으로 촬영을
                                               해드립니다. 기본 실사 제공은 제품의 도착 여부만을 확인할
                                               수 있게 업로드 해드리며 제품의 수량  오염  파손  오배송
                                               등의 사항은 확인되지 않을 수 있습니다. 상세한 확인을
                                               원하시는 경우 제품 도착 확인 시 상세 검수를 신청하시기
                                               바랍니다.<br><br>

                                               14. 신청서 작성 입고 확인 시 요청하는 부가서비스의 경우
                                               동일한 서비스라도 입고 확인 시 요청하는 사항에 대해서
                                               비용이 다르게 책정 될 수 있습니다.<br><br>

                                               15. 신청서 작성 시 운송장 번호 오기재로 인한 입고
                                               미입고된 제품을 추후 운송장번호를 수정  변경 하여
                                               입고요청을 해주시는 경우는 추가비용이 발생할 수
                                               있습니다.<br><br>

                                               16. 제품의 분리 나눔 배송 요청은 기본 지원 해드리지
                                               않으며 요청을 하시고자 하는 경우 카카오톡 채널 고객센터를 통해 분리
                                               나눔하고자 하는 제품의 이미지와 수량을 명확하게 표기하여
                                               요청해주셔야하며 해당 작업으로 인하여 추가비용이
                                               발생할 수 있습니다.<br><br>

                                               17. 고객님께서 컨펌 이후 제품에 이상이 있어서 전 단계로
                                               변경을 원하시거나 해당 제품에 대한 재검수 반품 등을
                                               원하시는 경우 해당 건에 대하여 추가 작업은 불가능하니
                                               컨펌은 신중히 확인 후 진행하시기 바랍니다.<br><br>

                                               18. 실사 옵션 사항을 확인하지 않고 배송 요청을 해주셔서
                                               일어나는 오배송  배송 누락 등의 사태에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서
                                               어떠한 보상도 해드리지 않습니다.
                                               주의 부탁 드립니다.<br><br>

                                               19. 제품 배송 지시 시 기본 묶음으로 지원되는
                                               그룹(신청서)은 제한은 없으나 추후 센터 사정에 따라
                                               추가비용이 발생할 수 있습니다.<br><br>

                                               20. 포장은 배송지시를 하는 순간 무게측정이 되어있지
                                               않더라도 무게측정을 위한 포장준비단계 및 포장진행 중일
                                               수 있어 배송지시 전단계로 요청은 제한되니 배송지시는
                                               신중이 진행부탁드립니다. 참고로 포장진행은 배송지시한
                                               신청서의 모든 제품을 하나의 박스로 포장을 원칙으로 하나
                                               제품의 크기가 하나의 박스로 포장이 불가능한 경우
                                               멀티박스로 포장되며 해당 비용은 무게 혹은 부피에 따라 청구됩니다.<br><br>

                                               21. 중국 택배의 특성상 배송이 완료가 되었다고 해도 저희
                                               센터에 도착하는 경우 2-3일 간의 시간차가 있을 수 있기
                                               때문에 최종 수취확인 날짜 기준 최대 3일 이후에 입고가
                                               되지 않는 경우에 한해서 문의가 가능하며 수취확인이 된
                                               날짜 기준으로 3일 이하의 건들에 대해서는 답변을 해드리지
                                               않습니다.<br><br>

                                               22. 국내 택배의 규격 외 화물(부피가 크거나 무게가
                                               초과되는 경우) 등의 경우 기존 택배사가 아닌 대신, 경동
                                               택배로 변경되어 배송되며 운송장번호가 변경되고 추가 착불
                                               금액이 발생됩니다. 한진택배  불가로 인해
                                               화물택배(경동/대신)로 변경은 통관 후 택배사 인계 과정에서
                                               결정되는 사안으로 변경된 당일 운송장번호 확인은 어려우며
                                               변경된 번호 확인을 위해서는 직접 센터에 문의를 주셔야
                                               확인이 가능하니 이점 참고하시기 바랍니다.<br><br>

                                               23. 제품의 크기 및 무게가 과도하여 우체국 택배에서 인계되어
                                               화물 택배(경동,대신)로 이전되어 추가 비용이 발생하는 건에
                                               대해서 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.<br><br>

                                               24. 결제 금액 불일치 입금자명이 주문인-수취인과 불일치할
                                               경우 입금 확인이 지연되거나 확인이 되지 않을 수 있습니다.
                                               <br><br>

                                               25. 제품이 저희 타오바바 배송대행지에 도착한 뒤 30일 까지는
                                               무료보관을 진행하며 30일 초과시 신청서 별로 1일당 1,000원의
                                               비용이 무게측정 시에 부과되며, 90일 이후에는 자동 폐기처리
                                               됩니다.노데이터 입고처리된 제품의 경우 30일 보관 이후 일괄
                                               폐기처리됩니다. 제품의 보관 중 분실 파손이 되는 경우는
                                               기본적으로 제품 금액만큼 환불처리를 해드리오나 최대 배상
                                               한도는 해당 제품을 작업했던 작업비용에 한합니다.
                                               <br><br>

                                               26. 입금 확인의 경우 실시간이 아닌 오전 오후 일괄 적용 되기
                                               때문에 사용자가 실시간 확인 시 지연이 있을 수 있습니다.
                                               <br><br>

                                               27. USD 150달러 이상 제품 가격을 신청해주시거나 식품
                                               목록통관 불가 상품등을 포함한 배송의 경우 자동으로
                                               간이통관으로 변경되는 것이 아닌 취하 대상이며 해당 경우
                                               관부가세 및 관세사 수수료 등의 추가 발생할 수 있으며 해당
                                               비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.
                                               <br><br>

                                               28. 지적 재산권 침해 상품(가품,디즈니,레고 등의 브랜드 상품)의
                                               경우 통관 취하시 해당 제품은 무조건 폐기되시며 기타 제품을
                                               포함한 배송의 경우 지재권 제품 폐기  그 외 제품 분할 작업으로
                                               인하여 세관에서 분할/폐기에 대한 수수료가 발생할 수 있습니다.
                                               해당 비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.
                                               <br><br>

                                               29. 제품명 오기재  품목 가격 고의 누락-제품 가격 수정 등으로
                                               인해 세관 통관 시 과태료(16만원가량)가 발생할 수 있으며 해당
                                               비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.<br><br>


                                               2]해외 배송 보상 불가 규정에 대한 안내 사항에 동의하시겠습니까?
                                               <br><br>

                                               국제 배송의 특성상 여러 과정을 거치기 때문에 국내 수령 후 제품의
                                               파손에 대하여 배상은 불가합니다. 해당 제품이 당사 도착 시 도착한
                                               운송장 번호와 별도 요청 시 제품의 실사를 업로드 해드리며, 고객님께서 이상이
                                               없다고 판단하여 컨펌이 완료된 제품은 이상이 없는 것으로 간주하여
                                               추후 제품의 파손  오염 등의 사항이 발생하더라도 당사에서는
                                               책임을 지지 않습니다. 제품의 파손이 우려되신다면 제품 도착 후
                                               에어캡 포장 배송 지시 시 특수 포장 등의 포장 보완을 요청하시면
                                               최선을 다하여 포장 작업을 진행하며 제품의 부피 무게에 따라
                                               기본 비용 보다 추가로 발생하거나 작업이 거부될 수 있습니다.
                                               <br><br>

                                               예시) 간단한 제품 에어캡(뽁뽁이) 포장 : 기본 요금 발생, 제품의
                                               부피가 큰 제품(자전거, 봇 등) 포장 : 기본 요금 +@ 비용이
                                               발생하거나 작업이 거부될 수 있습니다.<br><br>


                                               3] 컨펌 이후 이전 단계로 되돌리기 불가에 대한 안내<br><br>

                                               당사에서는 제품의 최종 확인은 고객님이 컨펌을 완료된 후
                                               고객님께서 제품 배송요청을 해주셔야 포장 및 무게측정을
                                               진행합니다. 때문에 고객님께서 컨펌을 하신 후 제품의 추가 확인
                                               추가 사진 요청  반품 등의 요청사항은 진행이 불가능합니다.
                                               때문에 반드시 제품의 실사를 확인 하신 후 컨펌을 진행하시기
                                               바랍니다. <br><br>

                                               4] 한 운송장번호의 제품 그룹에 대한 동의 사항<br><br>

                                               당사에서는 한 운송장번호로 온 제품은 하나의 그룹으로 묶이어
                                               실사 및 제품 확인이 가능합니다. 한 운송장번호로 도착한 제품을
                                               나누고자 하시는 경우는 분리 요청을 해주셔야 하며 분리 요청의
                                               경우 신청서 수량이 2개 초과하여 분리 요청하는 경우 분리되는
                                               신청서 1개당 1,000원의 비용이 발생하며 분리 난이도에 따라
                                               추가비용이 발생할 수 있습니다. <br><br>


                                               5] 통관 보류, 취하에 대한 동의사항<br><br>

                                               a)목록 통관시 제품의 통관 보류,취하 된 경우 관부가세(관세+부가세)
                                               및 관세사 비용(33,000원)이 발생할 수 있으며, 제품의 종류에 따라
                                               추가 비용이 발생할 수 있으며 미 납부 시 제품 수령이 불가합니다.
                                               <br><br>

                                               b)캐릭터 상품  상표권  수량 과다 등의 문제로 인한 통관 보류에
                                               대해 당사에서는 책임을 지지 않습니다.해당 제품이 문제가 있는
                                               상품일 경우 관세사 비용 및 관부가세 등 수수료가 발생할 수 있기
                                               때문에 주문하기 전 문제가 있는 제품인지 확인을 해보시기 바랍니다.
                                               <br><br>

                                               c)식품,식물 제품 등의 검역이 필요하여 간이통관이 필요한 제품을
                                               목록통관을 진행하신 경우 검역 비용 및 관세사 비용이 발생할 수
                                               있으며 이를 미납부 시 제품 수령이 불가하니 참고 바랍니다.
                                               <br><br>

                                               d)전자 제품은 1인당 1개씩만 진행이 가능하며 2개 이상 통관 시 1개의
                                               제품만 통관이 되며 나머지 제품은 폐기될 수 있습니다.<br><br>

                                               e)제품 품명 오기재  금액을 다르게 기재 등으로 인하여 세관에 통관
                                               진행 시 과태료가 발생할 수 있습니다.(드래그 작성 및 간편 작성의
                                               경우 고객님의 편의를 위해 제공할 뿐 오류 사항이 존재할 수 있습니다.
                                               반드시 신청서 작성 전  금액  수량  품명 등을 다시 한번 확인하시고
                                               수정해주시기 바랍니다.)<br><br>

                                               6. 오류입고 사항에 대한 안내 <br><br>

                                               제품의 오류 메시지 및 오류입고 진행의 경우 무료로 제공되는 서비스
                                               일 뿐이며 제품의 특성상 오류 메시지 및 오류 입고가 누락될 수 있습니다.
                                               해당 문제를 확인하시기 위해 제품의 실사 확인 및 컨펌 전 부가서비스
                                               요청을 통해 제품을 확인하실 수 있습니다. 오류가 없더라도 제품의 실사
                                               등을 반드시 확인해주시기 바랍니다.<br><br>

                                               7. 멀티박스 포장 및 부피과다한 제품 무게비용에 대한 안내 <br><br>

                                               배송지시 시 제품의 부피가 커서 한 박스로 포장이 불가능한 경우 자동으로
                                               멀티박스로 포장을 진행합니다. 멀티박스로 포장되는 경우 추가 박스당
                                               2,000원의 추가비용이 발생하며 무게측정 시 추가 청구 됩니다.제품이
                                               배송센터 도착 시 이미 큰 박스로 도착한 경우 기본 포장으로 배송이 됩니다.
                                               <br><br>

                                               8. 무료 사진 촬영 1회에 대한 안내 <br><br>

                                               무료 기본 실사 서비스는 1회 한정으로 진행되며 신청서 작성 시 기재한
                                               운송장번호를 기준으로 제품이 도착하였다는 것을 확인하기 위한 목적으로
                                               사진을 제공해드리며 색상  사이즈  오염  파손 등의 여부를 확인하는 데에는
                                               제대로 제공되지 않을 수 있습니다. 해당 색상 사이즈  오염  파손 등을
                                               확인하고자 하시는 경우는 상세 검수를 요청하시기 바라며 상세 검수는 제품의
                                               사이즈 및 작업 난이도에 따라 기본 수수료에 추가로 비용이 발생할 수 있습니다.
                                               <br><br>

                                               9. 화물 택배 인계 시 착불 비용 발생에 대한 동의사항 <br><br>

                                               한변의 길이가 100CM 이상  무게가 20kg 이상일 경우 한진 택배가 아닌 화물
                                               택배(대신/경동)로 변경되어 배송이 될 수 있습니다. 화물 택배로 발송이 되는
                                               경우 착불 비용이 발생하며 부피  무게에 대한 요금으로 자세한 사항은 대신
                                               택배/ 경동 택배 등 해당 화물택배 요금 규정에 따라 발생합니다. <br><br>

                                               10. 폐기 신청한 신청서 복구 불가에 대한 안내<br><br>

                                               폐기란 말 그대로 신청서는 폐기처리가 되며 이후 상태에서 어떠한 경우에도
                                               복구가 불가능합니다. 때문에 신청서를 폐기한 경우 폐기 후 재입고 요청
                                               제품의 추가 확인 등의 작업은 불가능 하기 때문에 폐기 진행 시 신중하게
                                               선택 하시기 바랍니다. <br><br>


                                               11. 반품 신청 시 수수료 발생 및 운송료 선불 지급에 대한 안내 <br><br>

                                               반품 신청 시 반품 수수료(2,000원)이 발생하며[구매대행 제외] 제품의
                                               운송료는 착불로 적용 시 판매자가 수취 거부하는 경우가 많아 제품의
                                               운송료는 고객님께서 운송료를 선불로 입금 해 주셔야 하며 판매자에게
                                               이야기 하여 해당 제품의 배송료에 대하여 환불 요청을 하실 수 있습니다.
                                               (당사에서 반송 시 진행하는 택배사의 비용과 판매자가 환불해주는
                                               배송료에 차이가 있을 수 있습니다.) <br><br>

                                               12. 확인 요청사항에 대한 검수 및 자동 비용 청구에 대한 안내 <br><br>

                                               고객님들께서 확인요청 사항 혹은 1:1게시판에 문의주시는 내용
                                               (사이즈  색상  오염 등의 확인) 등의 경우 임의로 상세 검수 등으로
                                               인지하여 우선 처리되며 해당 비용이 무게측정 시 자동으로 청구 됩니다.
                                               해당 요청으로 인한 추가 비용에 대하여 철회 및 취소가 불가함을 안내드립니다.
                                               <br><br>
                                           </p>
                                       </b-modal>
                                   </div>
                                   <div class="col-md-12 d-none">
                                       <div class="form-group">
                                           <div class="custom-file">
                                           <input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div> -->

                   </tab-content-item>



                   <tab-content-item :active="false" id="list" aria-labelled-by="list-tab">
                       <card>
                           <div class="p-3">
                               <form  @submit.prevent="submit" >
                                   <div class="row">

                                       <div class="col-md-12">
                                           <div class="custom-control custom-radio custom-control-inline">
                                               <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                               <label class="custom-control-label" for="customRadio7">오늘</label>
                                           </div>
                                           <div class="custom-control custom-radio custom-control-inline">
                                               <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                               <label class="custom-control-label" for="customRadio8">최근7일</label>
                                           </div>
                                           <div class="custom-control custom-radio custom-control-inline">
                                               <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                               <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                           </div>
                                           <!-- <div class="custom-control custom-switch custom-control-inline">
                                               <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checksstock">
                                               <label class="custom-control-label" for="customSwitch2">사입제품 포함 발주서만 보기</label>
                                           </div> -->
                                       </div>
                                       <div class="col-md-12 mt-2">
                                       <b-input-group>
                                               <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                                   <option>요청일</option>
                                               </select>
                                               <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                               <p>~</p>
                                               <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>

                                           </b-input-group>
                                       </div>
                                       <div class="col-md-4">
                                           <div class="form-group">
                                               <input type="text" class="form-control" placeholder="상품명" v-model="상품명">
                                           </div>
                                       </div>
                                       <div class="col-md-4">
                                           <div class="form-group">
                                               <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                                           </div>
                                       </div>
                                       <div class="col-md-4">
                                           <div class="form-group">
                                               <input type="text" class="form-control" placeholder="바코드" v-model="바코드">
                                           </div>
                                       </div>
                                   </div>
                                   <button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>
                                   <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                               </form>
                           </div>
                       </card>

                      <div v-if="showreqlist">
                           <div class="p-3">
                               <div class="row">
                                   <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                                   전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                                   </b-button>
                               </div>
                           </div>
                          <div class="status">
                              <div class="status-area">
                                  <div class="status-header">
                                      <div class="status-tit">
                                          구매
                                      </div>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('구매전')">구매전</a>
                                      <span>{{구매전}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('구매확인')">구매진행</a>
                                      <span>{{구매확인}}</span>
                                  </div>
                                  <!-- <div class="status-txt">
                                      <a @click="filterByStatus('구매확정')">구매확정</a>
                                      <span>{{구매확정}}</span>
                                  </div> -->
                              </div>
                              <div class="status-area">
                                  <div class="status-header">
                                      <div class="status-tit">
                                          발송
                                      </div>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('발송전')">발송전</a>
                                      <span>{{발송전}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('발송지연')">발송지연</a>
                                      <span>{{발송지연}}</span>
                                  </div>
                              </div>
                              <div class="status-area">
                                  <div class="status-header">
                                      <div class="status-tit">
                                          배송
                                      </div>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('배송중')">배송중</a>
                                      <span>{{배송중}}</span>
                                  </div>
                                  <!-- <div class="status-txt">
                                      <a @click="filterByStatus('배송지연예정')">배송지연예정</a>
                                      <span>{{배송지연예정}}</span>
                                  </div> -->
                                  <div class="status-txt">
                                      <a @click="filterByStatus('배송지연')">배송지연</a>
                                      <span>{{배송지연}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('배송완료')">배송완료</a>
                                      <span>{{배송완료}}</span>
                                  </div>
                              </div>
                              <div class="status-area">
                                  <div class="status-header">
                                      <div class="status-tit">
                                          입/출고
                                      </div>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('입고지연')">입고지연</a>
                                      <span>{{입고지연}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('입고완료')">입고완료</a>
                                      <span>{{입고완료}}</span>
                                  </div>
                              </div>
                              <div class="status-area">
                                  <div class="status-header">
                                      <div class="status-tit">
                                          오류
                                      </div>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('입고수량오류')">수량오류</a>
                                      <span>{{입고수량오류}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('입고상품오류')">상품오류</a>
                                      <span>{{입고상품오류}}</span>
                                  </div>
                                  <!-- <div class="status-txt">
                                      <a @click="filterByStatus('오류입고처리')">오류입고처리</a>
                                      <span>{{오류입고처리}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('오류반품처리')">오류반품처리</a>
                                      <span>{{오류반품처리}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('오류폐기처리')">오류폐기처리</a>
                                      <span>{{오류폐기처리}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('오류입고대기')">오류입고대기</a>
                                      <span>{{오류입고대기}}</span>
                                  </div>
                                  <div class="status-txt">
                                      <a @click="filterByStatus('반품폐기완료')">반품폐기완료</a>
                                      <span>{{반품폐기완료}}</span>
                                  </div> -->
                              </div>
                          </div>
                       </div>
                       <CDataTable v-if="showreqlist" v-model="tableSelectedItem" :headers="headers" :show-select="true" :items="reqlists" @input="onInput" itemKey="_id">
                           <template v-slot:요청일="{item}">
                               <div>{{getdate(item.요청일)}}</div>
                           </template>
                           <template v-slot:상품명="{item}">
                               <div style="display: flex; align-items: center; padding: 10px 0px; line-height: 2">
                                   <div>
                                       <img :src="item.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                   </div>
                                   <div>
                                       <div>{{item.상품명}}</div>
                                       <p class="mb-0" style="color: #676E8A;"><small>{{item.SKUID}}</small></p>
                                       <p class="mb-0" style="color: #676E8A;"><small>{{item.바코드}}</small></p>
                                   </div>
                               </div>
                           </template>
                           <template v-slot:구매링크="{item}">
                               <div style="max-width:300px;overflow:hidden;text-overflow: ellipsis; padding: 10px">
                                   {{ item.구매링크 }}
                               </div>
                           </template>
                           <template v-slot:action="{item}">
                               <div class="d-flex align-items-center list-action">
                                   <a class="badge bg-success mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="요청복사" href="javascript:void(0)" @click="copyRequest(item)">
                                       <i class="ri-pencil-line mr-0 action-img"></i>
                                   </a>
                                   <a class="badge badge-info mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="수정" href="javascript:void(0)" v-b-modal="modalID(item._id)">
                                       <i class="ri-eye-line mr-0 action-img"></i>
                                   </a>
                                   <b-modal :id="'modal' + item._id" size="xl" title="구매요청사항" ok-title="저장" cancel-title="닫기" @ok="saverequest(item)" @hidden="onModalHidden" @show="onModalShowList(item)">
                                       <div class="row">
                                           <div class="col-md-12">
                                               <h6 class="mt-2 mb-1">[ 출고유형 ]</h6>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="selectpo" @change="changeSelectPo(item)">
                                                   <label class="custom-control-label" for="customSwitch1">발주센터/입고예정일에 따라 박스 분류 (￦{{cost.poarrange}})</label>
                                               </div>
                                               <span v-if="item.요청내역.poarrange">※제품/발주 구분없이 적재되어 출고됩니다.</span>
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <div class="input-group" v-if="selectpo">
                                                       <input type="text" class="form-control" placeholder="발주서선택" aria-label="" v-model="selectpo" style="text-align:center;">
                                                       <div class="input-group-append">
                                                           <b-dropdown class="my-class" variant="primary" type="button" text="">
                                                               <b-dropdown-item @click="polistchange(b,modalData)" v-for="b,j in item.polists" :key="j">발주번호 : {{b.발주번호}} ({{b.발주센터}}/{{b.입고예정일}}) - {{b.sku수량}}개</b-dropdown-item>
                                                           </b-dropdown>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="col-md-12">
                                               <div class="custom-control custom-radio custom-control-inline">
                                                   <input type="radio" id="customRadio1" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.exportType" value="pallet">
                                                   <label class="custom-control-label" for="customRadio1">파렛트출고 (￦{{cost.exportpallet}}/pallet)</label>
                                               </div>
                                               <div class="custom-control custom-radio custom-control-inline">
                                                   <input type="radio" id="customRadio2" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.exportType" value="box">
                                                   <label class="custom-control-label" for="customRadio2">박스출고</label>
                                               </div>
                                               <span>※최대 박스 사이즈는 60cm(가로) x 40cm(세로) x 50cm(높이)이며, 상황에 따라 변경될 수 있습니다.</span>
                                           </div>

                                           <div class="col-md-12 mt-4 mb-1">
                                               <h6>[ 구매요청수량 ]</h6>
                                               <div class="custom-control-inline">
                                                   <input type="number" :min="0" class="form-control" placeholder="구매요청수량" aria-label="" v-model="item.구매요청수량" style="text-align:center;">
                                               </div>
                                           </div>

                                           <div class="col-md-12" v-if="item.구매요청수량 > item.발주수량 && item.발주수량 > 0 && item.요청내역.poarrange">
                                               <div class="custom-control custom-radio custom-control-inline">
                                                   <input type="radio" id="customRadio3" name="customRadio-13" class="custom-control-input" v-model="item.재고동봉" value=true>
                                                   <label class="custom-control-label" for="customRadio3">나머지 수량 출고</label>
                                               </div>
                                               <div class="custom-control custom-radio custom-control-inline">
                                                   <input type="radio" id="customRadio4" name="customRadio-13" class="custom-control-input" v-model="item.재고동봉" value=false>
                                                   <!-- <label class="custom-control-label" for="customRadio4">나머지 수량 재고보관 (￦{{cost.store}}/Pallet, 월)</label> -->
                                                   <label class="custom-control-label" for="customRadio4">나머지 수량 재고보관 (￦10000/Pallet, 월)</label>
                                               </div>
                                               <span>※나머지 수량 출고 시 별도의 박스로 함께 출고됩니다.</span>
                                           </div>


                                           <div class="col-md-12 mt-4 mb-1">
                                               <h6 v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">[ 판매구성수량 ]</h6>
                                               <div class="custom-control-inline">
                                                   <input type="number" :min="0" class="form-control" placeholder="판매구성수량" aria-label="" v-model="item.판매구성수량" style="text-align:center;">
                                               </div>
                                           </div>

                                           <div class="col-md-12 mt-4 mb-1">
                                               <h6 v-b-tooltip.left="'통관 시 필요한 정보를 기입해주세요 ex. 신고영문명 : shoes, 신고가격(USD) : 3.5, HS-CODE : 6401.10-0000'">[ 통관정보 ]</h6>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="item.신고영문명" style="text-align:center;" v-b-tooltip.left="'제품의 신고영문명을 간단히 작성해주세요.'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="number" :min="0" class="form-control" placeholder="신고가격(USD)" aria-label="" v-model="item.신고가격" style="text-align:center;" v-b-tooltip.left="'제품의 신고가격(USD)를 작성해주세요. 원/위안화는 달러로 환산해서 작성해주세요.'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="item.HSCODE" style="text-align:center;" v-b-tooltip.left="'통관할 제품의 HS-CODE를 작성해주세요 ex : 6401.10-1000'">
                                               </div>
                                               <a href="https://www.bandtrass.or.kr/hsnavi.do?page=F&site=" target="_blank">HS-CODE 검색</a>

                                           </div>





                                           <div class="col-md-12">
                                               <h6 class="mt-4 mb-1">[ 원산지작업 ]</h6>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="item.요청내역.barcode" @change="labelling(item.요청내역)">
                                                   <label class="custom-control-label" for="customSwitch2">바코드라벨(원산지,한글표시사항 포함) (￦{{cost.barcode}})</label>
                                               </div>
                                               <div class="custom-control custom-switch custom-control-inline" v-if="item.요청내역.barcode">
                                                   <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="item.바코드" style="text-align:center;" >
                                               </div>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="item.요청내역.sticker">
                                                   <label class="custom-control-label" for="customSwitch3">원산지스티커 (￦{{cost.sticker}})</label>
                                               </div>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="item.요청내역.stamp">
                                                   <label class="custom-control-label" for="customSwitch4">원산지도장 (￦{{cost.stamp}})</label>
                                               </div>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch5" v-model="item.요청내역.tag">
                                                   <label class="custom-control-label" for="customSwitch5">원산지택총 (￦{{cost.tag}})</label>
                                               </div>
                                           </div>

                                           <div class="col-md-12">
                                               <h6 class="mt-4 mb-1">[ 검수 및 포장작업 ]</h6>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="item.요청내역.default" @change="qadefault(modalData.요청내역)">
                                                   <label class="custom-control-label" for="customSwitch6">기본검수 (￦{{cost.default}})</label>
                                               </div>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch7" v-model="item.요청내역.opp">
                                                   <label class="custom-control-label" for="customSwitch7">투명 OPP 봉투 (견적서 참조)</label>
                                               </div>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch8" v-model="item.요청내역.LDPE">
                                                   <label class="custom-control-label" for="customSwitch8">불투명 LDPE 봉투 (견적서 참조)</label>
                                               </div>
                                           </div>
                                           <div class="col-md-12 mt-2">
                                               <div class="custom-control custom-switch custom-control-inline">
                                                   <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="item.요청내역.airpacking">
                                                   <label class="custom-control-label" for="customSwitch9">에어캡 포장 (견적서 참조)</label>
                                               </div>
                                               <p>※큰 부피의 제품(최대박스 사이즈의 1/5이상 사이즈)인 경우 별도 협의</p>
                                           </div>

                                           <div class="col-md-12 mt-3 d-flex align-items-center justify-contents-start">
                                               <h6>[한글표시사항]</h6>
                                           </div>
                                           <div class="col-md-12 d-flex align-items-center">
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="제품명" aria-label="" v-model="item.한글표시사항.제품명" @change="productNameWith(item)" style="text-align:center;"  v-b-tooltip.bottom="'ex) 쿠플러스 접시, 화이트 10인치'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.한글표시사항['수입원/판매원']" style="text-align:center;"  v-b-tooltip.left="'ex) 회사명'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="제조원" aria-label="" v-model="item.한글표시사항.제조원" style="text-align:center;"  v-b-tooltip.left="'ex) 회사명OEM'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="제조국" aria-label="" v-model="item.한글표시사항.제조국" style="text-align:center;"  v-b-tooltip.left="'ex) 중국'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="내용량" aria-label="" v-model="item.한글표시사항.내용량" style="text-align:center;"  v-b-tooltip.left="'ex) 300ml'">
                                               </div>
                                               <p></p>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="원료명및성분명(재질)" aria-label="" v-model="item.한글표시사항['원료명및성분명(재질)']" style="text-align:center;"  v-b-tooltip.left="'ex) 도자기제'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="상품유형" aria-label="" v-model="item.한글표시사항.상품유형" style="text-align:center;"  v-b-tooltip.left="'ex) 주방용품'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="사용시주의사항" aria-label="" v-model="item.한글표시사항.사용시주의사항" style="text-align:center;"  v-b-tooltip.left="'ex) 용도 이외에 사용금지'">
                                               </div>
                                               <div class="custom-control-inline">
                                                   <input type="text" class="form-control" placeholder="사용기준" aria-label="" v-model="item.한글표시사항.사용기준" style="text-align:center;"  v-b-tooltip.left="'ex) 14세 이상'">
                                               </div>
                                           </div>
                                           <div class="warning2" style="font-weight: 500">* 쿠팡에 등록한 상품명 + 옵션명을 같이 입력해주세요</div>

                                           <div class="col-md-12">
                                               <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                           </div>
                                           <div class="col-md-12">
                                               <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="item.요청내역.reqcontent"></textarea>
                                           </div>

                                       </div>
                                   </b-modal>
                                   <a class="badge bg-warning mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deletelist(item)">
                                       <i class="ri-delete-bin-line mr-0 action-img"></i>
                                   </a>
                               </div>
                           </template>
                       </CDataTable>
                       <div style="display: flex; padding: 20px 10px">
                           <div v-if="showreqlist">
                               <button type="button" class="btn btn-primary mr-2" @click="download">주문서 다운로드</button>
                           </div>
                           <div v-if="showreqlist">
                               <button type="button" class="btn btn-secondary mr-2" @click="showDelete">일괄삭제</button>
                           </div>
                       </div>
                   </tab-content-item>
               </tab-content>
           </div>
       </div>
        <b-modal v-model="isNotify" size="sm" title="공지" ok-title="오늘하루 보지 않기" cancel-title="닫기" @ok="todayModal()" @hide="isNotify = !isNotify">
            {{ notifyData?.content }}
        </b-modal>
       <!-- Page end  -->
   </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required ,integer	} from 'vuelidate/lib/validators';
// import { core } from '../../../config/pluginInit';
// import { VueEditor } from 'vue2-editor'
import _ from 'lodash'
import CDataTable from '@/components/common/CDataTable';
export default {
   name:'Reqpurchase',
    mixins: [validationMixin],
   validations: {
     purchaseno: { required },
     payment: { required ,integer},
     },
   components:{
   //    VueEditor,
       CDataTable
   },
   data(){
       return{
           tableSelectedItem: [],
           modalData: {},
           headers: [
               {text: '요청일', value: '요청일', align: 'center', width: 100, isSlot: true},
               {text: '진행현황', value: '진행현황', align: 'center', width: 100, isSlot: false},
               {text: '상품명', value: '상품명', align: 'left', width: 400, isSlot: true},
               {text: '구매요청수량', value: '구매요청수량', align: 'center', width: 120, isSlot: false},
               {text: '판매구성수량', value: '판매구성수량', align: 'center', width: 120, isSlot: false},
               {text: '구매링크', value: '구매링크', align: 'left', width: 300, isSlot: true},
               {text: '옵션1_중국어', value: '옵션1_중국어', align: 'left', width: 120, isSlot: false},
               {text: '옵션2_중국어', value: '옵션2_중국어', align: 'left', width: 120, isSlot: false},
               {text: 'Action', value: 'action', align: 'center', width: 150, isSlot: true}
           ],
           productinfos:[],
           change: false,

           checkedList: [],

           obj:{},
           obj1:[],
           obj2:{},
           checkstock : true,

           savedbstatus:false,
           isNotify: false,
           notifyData: null,

           추가sku:'',
           추가구매수량:'',
           구매링크:'',

           selectdate : 'today',
           startdate: this.getTodaywith(),
           enddate: this.getTodaywith(),
           selectoption:'요청일',
           SKUID: '',
           상품명: '',
           바코드: '',

           total: 0,
           구매전: 0,
           발송전: 0,
           배송중: 0,
           발송지연: 0,
           입고완료: 0,
           일괄삭제: 0,
           구매확인: 0,
           구매확정: 0,
           배송지연: 0,
        //    배송지연예정: 0,
           배송완료: 0,
           입고지연: 0,
           입고수량오류: 0,
           입고상품오류: 0,
           오류입고처리: 0,
           오류반품처리: 0,
           오류폐기처리: 0,
           오류입고대기: 0,
           반품폐기완료: 0,

           showspinner: false,
           showuploadspinner : false,

           allcheckreqlists : false,
           reqlists: [],
           reqlistsinit : [],

           title:"구매대행요청 (로켓, 일반LCL 제품)",
           desc1:"SKU ID를 입력 후 +버튼(혹은 Enter키)을 눌러 구매대행요청할 상품을 추가해 주세요 (SKU ID가 없는 경우 생략해 주세요)",
           desc2:"상품DB에 SKU ID/구매링크/옵션1_중국어/옵션2_중국어를 미리 업로드해 놓을 경우 자동으로 상품이 매핑됩니다.",
           desc3:"★ 현재는 1688링크만 구매 가능힙니다.(다른 링크의 경우 채널톡으로 문의해 주세요)",

           showreqlist: false,
           showlist: true,

           checkbarcode: true,
           checktag: false,
           checkstamp: false,
           checksticker: false,

           reqtype: "로켓배송",
           showreqrocket: false,
           showreqjet: false,
           showreqgeneral: false,
           purchaseno:'',
           payment:'',
           reqcontent:'',
           selectpo: false,

           allchecked: false,

           lists: [],
           downloadlists: [],
           currentindex: '',

           상품명init: '',

           uploadstatus:'여기를 클릭해서 선택하거나, 엑셀파일을 끌어다 놓으세요.',
           cost : {
               poarrange : 0,
               exportpallet : 0,
               barcode : 0,
               sticker : 0,
               stamp : 0,
               tag : 0,
               default : 0,
           }
       }
   },
   created(){
       document.addEventListener.call(window, "message", event => {
           if(event.data.res && event.data.res === 'importpurchaseinfo'){
               console.log(event.data);
               console.log(event.data.result[0].result);
               console.log(this.lists)
               console.log(this.currentindex)
               var index = this.currentindex;
               console.log(this.lists[this.currentindex])
               if(event.data.result[0].result){
                   if(!event.data.result[0].result.option1array){
                       if(this.lists[this.currentindex].옵션1_중국어){
                           alert('옵션1_중국어가 없는 구매링크 입니다. 옵션을 확인해주세요')
                       }
                       this.lists[this.currentindex].상품매칭.옵션1_중국어 = [];
                       this.lists[this.currentindex].옵션1_중국어 = '';
                   } else {
                       this.lists[this.currentindex].상품매칭.옵션1_중국어 = event.data.result[0].result.option1array;
                   }
                   if(!event.data.result[0].result.option2array){
                       if(this.lists[this.currentindex].옵션2_중국어){
                           alert('옵션2_중국어가 없는 구매링크 입니다. 옵션을 확인해주세요')
                       }
                       this.lists[this.currentindex].상품매칭.옵션2_중국어 = [];
                       this.lists[this.currentindex].옵션2_중국어 = '';
                   } else {
                       this.lists[this.currentindex].상품매칭.옵션2_중국어 = event.data.result[0].result.option2array;
                   }

                   this.lists[this.currentindex].구매링크 = this.lists[this.currentindex].구매링크init;
                   this.lists[this.currentindex].showspinner = false;

                   if(this.checkstock && Object.keys(event.data.result[0].result.info).length > 0){
                       // console.log(this.lists[this.currentindex].구매링크)
                       var obj = event.data.result[0].result.info.obj;
                       var obj1 = event.data.result[0].result.info.obj1;
                       var obj2 = event.data.result[0].result.info.obj2;
                       this.obj1 = obj1;
                       this.obj2 = obj2;
                       this.obj = obj;
                       if(obj1){
                           // console.log(this.lists[this.currentindex].구매링크)
                           if(obj1.length == 0){
                              if(Object.keys(event.data.result[0].result.info.obj).length > 0){
                                   var stockqty = event.data.result[0].result.info.obj?.globalData?.orderParamModel?.orderParam?.canBookedAmount;
                                   if(stockqty == 0){
                                       alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                                   } else {
                                       alert('구매 가능한 재고수량 : ' + stockqty)
                                   }
                                   this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                              }
                           } else {
                               if(this.lists[this.currentindex]?.구매링크 !== undefined && this.lists[this.currentindex]?.옵션1_중국어 !== undefined && this.lists[this.currentindex]?.옵션2_중국어 !== undefined){
                                   if(obj1.length == 1) {
                                       var name = obj1[0].value.find(e => e.name.includes(this.lists[this.currentindex].옵션1_중국어)).name;
                                       stockqty = obj2[name]?.canBookCount;
                                       if(stockqty == 0){
                                           alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                                       } else {
                                           alert('구매 가능한 재고수량 : ' + stockqty)
                                       }
                                       if(this.lists[this.currentindex].등록이미지 == ''){
                                           this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                                       }
                                   } else {
                                       if(!obj1[0].value.find(e => e.name.includes(this.lists[this.currentindex].옵션1_중국어))){
                                           alert('해당 링크에 "' + this.lists[this.currentindex].옵션1_중국어 + '" 옵션명이 존재하지 않습니다.')
                                           this.lists[this.currentindex].옵션1_중국어 = undefined;
                                       } else {
                                           var name1 = obj1[0].value.find(e => e.name.includes(this.lists[this.currentindex].옵션1_중국어)).name;
                                           if(!obj1[1].value.find(e => e.name.includes(this.lists[this.currentindex].옵션2_중국어))){
                                               alert('해당 링크에 "' + this.lists[this.currentindex].옵션2_중국어 + '" 옵션명이 존재하지 않습니다.')
                                               this.lists[this.currentindex].옵션2_중국어 = undefined;
                                           } else {
                                               var name2 = obj1[1].value.find(e => e.name.includes(this.lists[this.currentindex].옵션2_중국어)).name;
                                               name = name1 + "&gt;" + name2;
                                               stockqty = obj2[name]?.canBookCount;
                                               if(stockqty == 0){
                                                   alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                                               } else {
                                                   alert('구매 가능한 재고수량 : ' + stockqty)
                                               }
                                               if(this.lists[this.currentindex].등록이미지 == ''){
                                                   this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                                               }
                                           }
                                       }
                                   }
                               }
                           }
                       } else {
                           if(Object.keys(event.data.result[0].result.info.obj).length > 0){
                               stockqty = event.data.result[0].result.info.obj?.globalData?.orderParamModel?.orderParam?.canBookedAmount;
                               if(stockqty == 0){
                                   alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                               } else {
                                   alert('구매 가능한 재고수량 : ' + stockqty)
                               }
                               this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                           }
                       }
                       this.currentindex = '';
                   }

                   if(this.lists[index].구매링크){
                       if(this.lists[index].옵션1_중국어){
                           if(this.lists[index].옵션2_중국어){
                               this.lists[index].매칭상태 = '매칭완료'
                           } else {
                               if(this.lists[index].상품매칭.옵션2_중국어.length > 0){
                                   this.lists[index].매칭상태 = '매칭필요'
                               } else {
                                   this.lists[index].매칭상태 = '매칭완료'
                               }
                           }
                       } else {
                           if(this.lists[index].상품매칭.옵션1_중국어.length > 0){
                               this.lists[index].매칭상태 = '매칭필요'
                           } else {
                               this.lists[index].매칭상태 = '매칭완료'
                           }
                       }
                   }
                   this.showlist = true;
               } else {
                   alert('유효하지 않은 구매링크 입니다.')
               }

           }
       });
   },
   methods: {
       onInput(eventValue) {
         console.log('click input', eventValue)
       },
       todayModal() {
           const today = new Date().toISOString().split('T')[0];
           localStorage.setItem('modalClosedDate', today);
           this.isNotify = false;
       },
       async copyRequest(list){
           if(list._id){
               delete list._id
           }
           let newObj = this.deepClone(list);
           newObj.진행현황 = '구매전';
           newObj.구매id = '';
           this.reqinit(newObj);
           let result = await this.confirmreq(newObj);
           this.lists.push(result);
           alert('복사등록완료')
       },
       productNameWith(list){
           // if(confirm('변경된 한글표시사항 제품명에 따라 상품명도 변경하시겠습니까?')){
               list.상품명 = list.한글표시사항.제품명;
           // }
       },
       checktest(bool){console.log(bool)},
       getreqlists(){
           this.showlist = false;
           axios.post('/api/reqpurchase/getreqlist',[])
           .then((res) => {
               console.log(res)
               if (res.data.notifyData) {
                   const lastClosed = localStorage.getItem('modalClosedDate');
                   const today = new Date().toISOString().split('T')[0];

                   if (lastClosed !== today) {
                       this.isNotify = true;
                   }
                   this.notifyData = res.data.notifyData
               }
               if(res.data.lists || res.data.lists == []){
                   this.lists = res.data.lists;
                   this.showlist = true;
               } else {
                   alert(res.data.요청결과)
                   this.showlist = true;
               }
           })
           .catch(console.log)
       },
       async savereqlist(){
           this.change = true;
           console.log(this.lists)
           try{
               var res = await axios.post('/api/reqpurchase/savereqlist',this.lists);
               alert(res.data.요청결과)
           }catch(err){
               console.log(err)
           }
       },
       addProductName(list){
           list.상품명 = this.상품명init
       },
       labelling(요청내역){
           if(요청내역.barcode){
               요청내역.한글표시사항 = true
           } else {
               요청내역.한글표시사항 = false
           }
       },
       qadefault(요청내역){
           if(!요청내역.default){
               if(요청내역.barcode || 요청내역.sticker || 요청내역.stamp || 요청내역.tag){
                   alert('개별 원산지 작업을 하는 경우 기본검수는 필수항목 입니다.')
                   요청내역.default = true;
               }
           }
       },
       async savereq($event,list,index){
           this.change = true;
           $event.preventDefault();
           var foo = await this.confirmlist(list)
           if(!foo){
               return
           }
           list.요청사항 = '등록완료';
           this.$bvModal.hide('modalreq' + index);
       },
       cancelreq(index){
           this.$bvModal.hide('modalreq' + index);
       },
       changeSelectPo(item) {
           console.log(this.selectpo)
           const selectedIndex = this.reqlists.findIndex(i => i._id === item._id);
           this.selectpo = !this.selectpo
           if (!this.selectpo) {
               this.reqlists[selectedIndex].selectpo = ''
           }
       },
       async saverequest(item){
           console.log('change Item', item)
           const selectedIndex = this.reqlists.findIndex(i => i._id === item._id);
           if(this.reqlists[selectedIndex].진행현황 === '구매전'){
               this.reqlistsinit[selectedIndex] = this.deepClone(this.reqlists[selectedIndex])
               alert('상품 수정 시 반드시 채널톡으로 관련내용 전달해주세요')
               await this.updatereqlist([item])
               this.$forceUpdate();
           } else {
               if(this.reqlists[selectedIndex]['구매요청수량'] !== this.reqlistsinit[selectedIndex]['구매요청수량']){
                   alert('이미 구매 진행된 상품은 구매요청수량을 변경할 수 없습니다.')
                   return
               } else {
                   this.reqlistsinit[selectedIndex] = this.deepClone(this.reqlists[selectedIndex])
                   alert('상품 수정 시 반드시 채널톡으로 관련내용 전달해주세요')
                   await this.updatereqlist([item])
                   this.$forceUpdate();
               }
           }
       },
       async updatereqlist(arr){
           this.change = true;
           if(arr.length == 1){
               this.showlist = false
               arr.forEach(e => {
                   e.바코드 = e.바코드.trim()
               })
               axios.post('/api/reqpurchase/updatereqpurchase',arr)
               .then((res) => {
                   if(res.data.요청결과 == '변경완료'){
                       alert(res.data.요청결과)
                       this.showlist = true
                   } else {
                       alert(res.data.요청결과)
                       console.log(res.data)
                   }
               })
               .catch(console.log)
           } else {
               this.showlist = false
               axios.post('/api/reqpurchase/updatereqpurchase',this.reqlists)
               .then((res) => {
                   if(res.data.요청결과 == '변경완료'){
                       this.showlist = true
                       alert(res.data.요청결과)
                   } else {
                       alert(res.data.요청결과)
                       console.log(res.data)
                   }
               })
               .catch(console.log)
           }
       },
       usergrade(){
           // console.log(this.$store.state.user)
           if(this.$store.state.user.grade == 'general'){
               this.cost.poarrange = 100
               this.cost.exportpallet = 20000
               this.cost.barcode = 500
               this.cost.sticker = 300
               this.cost.stamp = 300
               this.cost.tag = 300
               this.cost.default = 300
               this.cost.store = 5000
           }
           if(this.$store.state.user.grade == 'vip'){
               this.cost.poarrange = 100;
               this.cost.exportpallet = 20000
               this.cost.barcode = 300
               this.cost.sticker = 200
               this.cost.stamp = 200
               this.cost.tag = 300
               this.cost.default = 200
               this.cost.store = 3000
           }
           if(this.$store.state.user.grade == 'vvip'){
               this.cost.poarrange = 100;
               this.cost.exportpallet = 20000
               this.cost.barcode = 300
               this.cost.sticker = 200
               this.cost.stamp = 200
               this.cost.tag = 300
               this.cost.default = 200
               this.cost.store = 0
           }
       },
       async uploadreqfile(req){
           this.change = true;
           if(req.length == 0){
               alert('구매요청수량이 없습니다.')
               return
           }
           console.log('req'. req)
           var 요청건수 = req.filter(e => parseInt(e.구매요청수량) > 0).length;
           var 요청수량 = req.reduce((pv,cv) => {return pv + parseInt(cv.구매요청수량)},0);
           if(confirm(요청건수 + '건(총수량:' + 요청수량 + '개)의 내역을 구매요청하시겠습니까?')){
               var arr = [];
               var nosku = [];
               for(var i=0;i<req.length;i++){
                   var e = req[i];
                   console.log(e.바코드)
                   var obj = await this.addexcellists(e);
                   if(obj.nosku){
                       nosku.push(e)
                   } else {
                       console.log(obj)
                       arr.push(this.deepClone(obj));
                   }
               }
               if(nosku.length > 0){
                   var noskulist = ''
                   for(var j=0;j<nosku.length;j++){
                       if(j == nosku.length - 1){
                           noskulist += nosku[j]
                       } else {
                           noskulist += nosku[j] + '\n'
                       }
                   }
                   alert('아래의 바코드는 SKU정보가 없어서 추가되지 않았습니다. SKU리스트 수집을 먼저 진행해 주세요. \n' + noskulist)
               } else {
                   await this.addreqlist(arr)
               }
               this.showuploadspinner = false;
               this.uploadstatus = '여기를 클릭해서 선택하거나, 엑셀파일을 끌어다 놓으세요.'
               this.$bvModal.hide('excelupload');
           }
       },
       async addreqlist(lists){
           console.log(lists)
           try{
               var res = await axios.post('/api/reqpurchase/addreqlist',lists)
               console.log(res)
               if(res){
                   alert(lists.length + '건이 추가되었습니다.')
                   this.getreqlists();
                   this.$bvModal.hide('reqpurchase');
                   return
               }
           }catch(e){
               console.log(e)
           }
           alert('추가실패');
       },
       async addexcellists(e){
           if(this.$store.state.productdb && this.$store.state.productdb.find(ele => ele.바코드 == e.바코드)){
               var ob = this.$store.state.productdb.find(ele => ele.바코드 == e.바코드)
               var obj = {
                   checked : false,
                   showspinner : false,
                   SKUID : ob['SKU ID'],
                   바코드 : ob.바코드,
                   상품명 : ob.상품명,
                   상품명init : '',
                   진행현황 : '구매전',
                   구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                   구매링크init : ob.구매링크 ? ob.구매링크 : undefined,
                   옵션1_중국어 : ob.옵션1_중국어 ? ob.옵션1_중국어 : undefined,
                   옵션2_중국어 : ob.옵션2_중국어 ? ob.옵션2_중국어 : undefined,
                   옵션이미지: ob.옵션이미지 ? ob.옵션이미지 : undefined,
                   매칭상태 : ob.매칭상태 ? ob.매칭상태 : '매칭필요',
                   상품매칭 : {
                       옵션1_중국어 : [],
                       옵션2_중국어 : [],
                   },
                   구매가격 : '',
                   이전구매가격: ob.해외구매단가 ? ob.해외구매단가 : '',
                   구매재고 : '',
                   구매요청수량 : e.구매요청수량,
                   판매구성수량: ob.판매구성수량 ? (ob.판매구성수량 == '단품' ? 1 : ob.판매구성수량) : 1,
                   묶음구성여부 : ob.묶음구성여부 ? ob.묶음구성여부 : false,
                   묶음구성수량 : ob.묶음구성수량 ? ob.묶음구성수량 : '',
                   묶음대상바코드 : ob.묶음대상바코드 ? ob.묶음대상바코드 : '',
                   신고영문명 : ob.신고영문명 ? ob.신고영문명 : '',
                   신고가격 : ob['신고가격(USD)'] ? ob['신고가격(USD)'] : '',
                   HSCODE : ob['HS-CODE'] ? ob['HS-CODE'] : '',
                   재고동봉 : true,
                   등록이미지 : ob.등록이미지,
                   요청사항 : '요청등록',
                   reqtype : '',
                   sizeX : ob.sizeX ? ob.sizeX : '',
                   sizeY : ob.sizeY ? ob.sizeY : '',
                   sizeZ : ob.sizeZ ? ob.sizeZ : '',
                   weight : ob.weight ? ob.weight : '',
                   요청내역 : {
                       default : true,
                       poarrange : e.발주번호 ? true : false,
                       boxnumber : '',
                       한글표시사항 : true,
                       barcode : true,
                       sticker : false,
                       stamp : false,
                       tag : false,
                       reqcontent : e.요청사항,
                       opp : false,
                       LDPE : false,
                       airpacking : false,
                       exportType : 'pallet',
                   },
                   한글표시사항 : {
                       제품명 : ob.상품명,
                       '수입원/판매원' : ob['수입원/판매원'],
                       제조원 : ob.제조원,
                       제조국 : ob.제조국,
                       내용량 : ob.내용량,
                       '원료명및성분명(재질)' : ob['원료명및성분명(재질)'],
                       상품유형 : ob.상품유형,
                       사용시주의사항 : ob.사용시주의사항,
                       사용기준 : ob.사용기준,
                   },
                   polists : [],
                   selectpo : e.발주번호 ? e.발주번호 : '',
               }
               this.reqinit(obj);
               var newObj = await this.confirmreq(obj);
               var foo = await this.confirmlist(newObj)
               if(foo){
                   newObj.요청사항 = '등록완료';
               }
               return newObj
           } else {
               return {nosku:true}
           }
       },
       pricechange(obj){
           this.change = true;
           if(obj.매칭상태 == '매칭필요'){
               obj.구매가격 = '';
               return
           }
           console.log(obj)
           if(obj.item && obj.구매요청수량){
               var option1array = [];
               var option2array = [];
               var item = obj.item;
               if(item.props_list && Object.keys(item.props_list).length > 0){
                   Object.keys(item.props_list).forEach(e => {
                       if(e[0] == '0'){
                           option1array.push({
                               properties : e,
                               name : item.props_list[e].split(":")[1],
                               imageUrl : item.props_img[e] ? item.props_img[e] : '',
                           })
                       }
                       if(e[0] == '1'){
                           option2array.push({
                               properties : e,
                               name : item.props_list[e].split(":")[1],
                               imageUrl : item.props_img[e] ? item.props_img[e] : '',
                           })
                       }
                   })
               }

               if(option1array.length == 0){
                   if(item.priceRange){
                       for(var i=0;i<item.priceRange.length;i++){
                           obj.구매가격 = parseFloat(item.priceRange[i][1]);
                           if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                               break
                           }
                       }
                   } else {
                       obj.구매가격 = parseInt(item.price);
                   }
               } else {
                   if(option2array.length == 0){
                       if(item.priceRange){
                           for(i=0;i<item.priceRange.length;i++){
                               obj.구매가격 = parseFloat(item.priceRange[i][1]);
                               if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                                   break
                               }
                           }
                       } else {
                           obj.구매가격 = parseInt(item.price);
                       }
                   } else {
                       if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                           obj.구매가격 = '';
                       } else {
                           if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                               obj.구매가격 = '';
                           } else {
                               var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                               var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                               if (!item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties))) {
                                   obj.옵션이미지 = undefined
                                   obj.옵션1_중국어 = undefined;
                                   obj.옵션2_중국어 = undefined;
                                   obj.매칭상태 = '매칭필요'
                                   obj.구매가격 = ''
                               } else {
                                   obj.구매가격 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).price);
                               }
                           }
                       }
                   }
               }
           }
       },
       async addlists(){
           this.change = true;
           this.showlist = false;
           if(this.추가sku == ''){
               var obj = {
                   checked : false,
                   showspinner : false,
                   SKUID : '',
                   바코드 : '',
                   상품명 : '',
                   구매링크 : undefined,
                   구매링크init : undefined,
                   옵션1_중국어 : undefined,
                   옵션2_중국어 : undefined,
                   옵션이미지: undefined,
                   매칭상태 : '매칭필요',
                   상품매칭 : {
                       옵션1_중국어 : [],
                       옵션2_중국어 : [],
                   },
                   구매가격 : '',
                   이전구매가격 : '',
                   구매재고 : '',
                   구매요청수량 : '',
                   판매구성수량 : 1,
                   묶음구성여부 : false,
                   묶음구성수량 : '',
                   묶음대상바코드 : '',
                   신고영문명 : '',
                   신고가격 : '',
                   HSCODE : '',
                   진행현황 : '구매전',
                   재고동봉 : true,
                   등록이미지 : '',
                   요청사항 : '요청등록',
                   reqtype : '',
                   sizeX : '',
                   sizeY : '',
                   sizeZ : '',
                   weight : '',
                   요청내역 : {
                       default : true,
                       poarrange : false,

                       한글표시사항 : false,
                       barcode : false,
                       sticker : true,
                       stamp : false,
                       tag : false,
                       reqcontent : '',
                       opp : false,
                       LDPE : false,
                       airpacking : false,
                       exportType : 'pallet',
                   },
                   한글표시사항 : {
                       제품명 : '',
                       '수입원/판매원' : '',
                       제조원 : '',
                       제조국 : '',
                       내용량 : '',
                       '원료명및성분명(재질)' : '',
                       상품유형 : '',
                       사용시주의사항 : '',
                       사용기준 : '',
                   },
                   polists : [],
                   selectpo : '',
               }
               this.lists.unshift(obj)
           } else {
               if(this.$store.state.productdb && !this.$store.state.productdb.find(e => parseInt(e['SKU ID']) === parseInt(this.추가sku))){
                   alert('상품DB에 존재하지 않는 SKU ID입니다.')
                   this.추가sku = '';
                   this.showlist=true
                   return
               }
               var ob = this.$store.state.productdb.find(e => parseInt(e['SKU ID']) === parseInt(this.추가sku))
               if(ob.요청내역){
                   var 요청내역 = ob.요청내역
               } else {
                   요청내역 = {
                       default : true,
                       poarrange : false,

                       boxnumber : '',
                       한글표시사항 : true,
                       barcode : true,
                       sticker : false,
                       stamp : false,
                       tag : false,
                       reqcontent : '',
                       opp : false,
                       LDPE : false,
                       airpacking : false,
                       exportType : 'box',
                   }
               }

               obj = {
                   checked : false,
                   showspinner : false,
                   SKUID : ob['SKU ID'],
                   바코드 : ob.바코드 ? ob.바코드 : '',
                   상품명 : ob.상품명,
                   상품명init : '',
                   진행현황 : '구매전',
                   구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                   구매링크init : ob.구매링크 ? ob.구매링크 : undefined,
                   옵션1_중국어 : ob.옵션1_중국어 ? ob.옵션1_중국어 : undefined,
                   옵션2_중국어 : ob.옵션2_중국어 ? ob.옵션2_중국어 : undefined,
                   옵션이미지: ob.옵션이미지 ? ob.옵션이미지 : undefined,
                   매칭상태 : ob.매칭상태 ? ob.매칭상태 : '매칭필요',
                   상품매칭 : {
                       옵션1_중국어 : [],
                       옵션2_중국어 : [],
                   },
                   구매가격: '',
                   이전구매가격: ob.해외구매단가 ? ob.해외구매단가 : '',
                   구매재고 : '',
                   등록이미지 : ob.등록이미지,
                   구매요청수량 : '',
                   판매구성수량: ob.판매구성수량 ? (ob.판매구성수량 == '단품' ? 1 : ob.판매구성수량) : 1,
                   묶음구성여부 : ob.묶음구성여부 ? ob.묶음구성여부 : false,
                   묶음구성수량 : ob.묶음구성수량 ? ob.묶음구성수량 : '',
                   묶음대상바코드 : ob.묶음대상바코드 ? ob.묶음대상바코드 : '',
                   신고영문명 : ob.신고영문명 ? ob.신고영문명 : '',
                   신고가격 : ob['신고가격(USD)'] ? ob['신고가격(USD)'] : '',
                   HSCODE : ob['HS-CODE'] ? ob['HS-CODE'] : '',
                   재고동봉 : true,
                   요청사항 : '요청등록',
                   reqtype : '',
                   sizeX : ob.sizeX ? ob.sizeX : '',
                   sizeY : ob.sizeY ? ob.sizeY : '',
                   sizeZ : ob.sizeZ ? ob.sizeZ : '',
                   weight : ob.weight ? ob.weight : '',
                   요청내역 : 요청내역,
                   한글표시사항 : {
                       제품명 : ob.상품명,
                       '수입원/판매원' : ob['수입원/판매원'],
                       제조원 : ob.제조원,
                       제조국 : ob.제조국,
                       내용량 : ob.내용량,
                       '원료명및성분명(재질)' : ob['원료명및성분명(재질)'],
                       상품유형 : ob.상품유형,
                       사용시주의사항 : ob.사용시주의사항,
                       사용기준 : ob.사용기준,
                   },
                   polists : [],
                   selectpo : '',
               }
               var newObj = await this.confirmreq(obj);
               this.lists.unshift(this.deepClone(newObj));
           }
           await this.reqinit(this.lists[0])
           this.추가sku = '';
           this.추가구매수량 = '';
           setTimeout(()=>{
            this.showlist = true;
           },1)
       },
       async confirmreq(obj){
           if(!obj.구매링크){
               obj.showspinner = false;
               return obj
           }
           obj.구매링크init = obj.구매링크;
           obj.매칭상태 = '매칭필요'
           var item = await this.getProductInfo(obj.구매링크);
           item = item.data.result[0].result.result

           let option1array = [];
           let option2array = [];

           if (item.productSkuInfos) {
               item.productSkuInfos.forEach((e) => {
                   if (!option1array.find(option => option.name === e.skuAttributes[0].value)) {
                       option1array.push({
                           name: e.skuAttributes[0].value,
                           imageUrl: e.skuAttributes[0].skuImageUrl ? e.skuAttributes[0].skuImageUrl : '',
                       })
                   }

                   if (e.skuAttributes[1]) {
                       if (!option2array.find(option => option.name === e.skuAttributes[1].value)) {
                           option2array.push({
                               name: e.skuAttributes[1].value,
                               imageUrl: e.skuAttributes[1].skuImageUrl ? e.skuAttributes[1].skuImageUrl : '',
                           })
                       }
                   } else {
                       option2array = []
                   }
               })

               if(option1array.length === 0){
                   obj.상품매칭.옵션1_중국어 = [];
                   obj.상품매칭.옵션2_중국어 = [];
                   obj.옵션1_중국어 = '';
                   obj.옵션2_중국어 = '';
                   obj.구매재고 = item.productSaleInfo.amountOnSale;
                   obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : '';
                   obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : '';
                   obj.매칭상태 = '매칭완료'
               } else {
                   if (obj.옵션1_중국어 || obj.옵션2_중국어) {
                       obj.옵션1_중국어 = obj.옵션1_중국어 ? obj.옵션1_중국어 : undefined;
                       obj.옵션2_중국어 = obj.옵션2_중국어 ? obj.옵션2_중국어 : undefined
                       obj.매칭상태 = '매칭완료'
                   } else {
                       obj.옵션1_중국어 = undefined;
                       obj.옵션2_중국어 = option2array.length === 0 ? '' : undefined;
                       obj.매칭상태 = '매칭필요'
                   }
                   obj.옵션이미지 = undefined
                   obj.구매가격 = ''
                   obj.상품매칭.옵션1_중국어 = option1array;
                   obj.상품매칭.옵션2_중국어 = option2array;
                   obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : '';
                   obj.item = item
               }
           }
           obj.showspinner = false;
           return obj
           // if(!obj.구매링크 || obj.구매링크 == '사입'){
           //     obj.showspinner = false;
           //     return obj
           // }
           // obj.구매링크init = obj.구매링크;
           // obj.매칭상태 = '매칭필요'
           // var item = await this.getProductInfo(obj.구매링크);
           // obj.item = item;
           //
           // if(item.format_check == 'fail'){
           //     obj.구매재고 = '구매불가';
           //     obj.매칭상태 = '매칭필요';
           //     obj.showspinner = false;
           //     return obj
           // }
           //
           // var option1array = [];
           // var option2array = [];
           // if(item.props_list && Object.keys(item.props_list).length > 0){
           //     item.skus.sku.forEach(e => {
           //         if(e.properties.includes(";")){
           //             var properties1 = e.properties.split(';')[0];
           //             var properties2 = e.properties.split(';')[1];
           //             if(!option1array.find(ele => ele.properties == properties1)){
           //                 var name = e.properties_name.split(";" + properties2)[0].replace(properties1 + ":","")
           //                 name = name.slice(name.search(":")+1)
           //                 option1array.push({
           //                     properties : properties1,
           //                     name : name,
           //                     imageUrl : item.props_img[properties1] ? item.props_img[properties1] : '',
           //                 })
           //             }
           //             if(!option2array.find(ele => ele.properties == properties2)){
           //                 name = e.properties_name.split(";" + properties2)[1].slice(1);
           //                 name = name.slice(name.search(":")+1)
           //                 option2array.push({
           //                     properties : properties2,
           //                     name : name,
           //                     imageUrl : item.props_img[properties2] ? item.props_img[properties2] : '',
           //                 })
           //             }
           //         } else {
           //             name = e.properties_name.replace(e.properties + ":","");
           //             name = name.slice(name.search(":")+1)
           //             option1array.push({
           //                 properties : e.properties,
           //                 name : name,
           //                 imageUrl : item.props_img[e.properties] ? item.props_img[e.properties] : '',
           //             })
           //         }
           //     })
           //
           //
           //
           //     // Object.keys(item.props_list).forEach(e => {
           //     //     if(e[0] == '0'){
           //     //         option1array.push({
           //     //             properties : e,
           //     //             name : item.props_list[e].split(":")[1],
           //     //             imageUrl : item.props_img[e] ? item.props_img[e] : '',
           //     //         })
           //     //     }
           //     //     if(e[0] == '1'){
           //     //         option2array.push({
           //     //             properties : e,
           //     //             name : item.props_list[e].split(":")[1],
           //     //             imageUrl : item.props_img[e] ? item.props_img[e] : '',
           //     //         })
           //     //     }
           //     // })
           // }
           // if(option1array.length == 0){
           //     obj.상품매칭.옵션1_중국어 = [];
           //     obj.상품매칭.옵션2_중국어 = [];
           //     obj.옵션1_중국어 = '';
           //     obj.옵션2_중국어 = '';
           //     obj.구매재고 = parseInt(item.num);
           //     obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
           //     obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
           //     obj.매칭상태 = '매칭완료'
           // } else {
           //     if(option2array.length == 0){
           //         var imgUrl = '';
           //         if(!option1array.find(e => e.name == obj.옵션1_중국어)){
           //             obj.옵션1_중국어 = undefined;
           //             obj.옵션이미지 = undefined;
           //             obj.구매재고 = 0;
           //         } else {
           //             var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
           //             obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == option1properties)?.quantity);
           //             if(item.prop_imgs.prop_img.length > 0){
           //                 imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties)?.url;
           //             }
           //             obj.옵션이미지 = imgUrl;
           //             obj.매칭상태 = '매칭완료'
           //         }
           //         obj.상품매칭.옵션1_중국어 = option1array;
           //         obj.상품매칭.옵션2_중국어 = [];
           //         obj.옵션2_중국어 = '';
           //         obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
           //     } else {
           //         imgUrl = '';
           //         if(!option1array.find(e => e.name == obj.옵션1_중국어)){
           //             obj.옵션1_중국어 = undefined;
           //             obj.옵션이미지 = undefined;
           //             obj.구매재고 = 0;
           //         } else {
           //             option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
           //             if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
           //                 imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
           //             }
           //             obj.옵션이미지 = imgUrl;
           //             if(!option2array.find(e => e.name == obj.옵션2_중국어)){
           //                 obj.옵션2_중국어 = undefined;
           //                 obj.구매재고 = 0;
           //             } else {
           //                 var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
           //                 obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties))?.quantity);
           //                 if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option2properties)){
           //                     imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option2properties).url;
           //                 }
           //                 obj.옵션이미지 = imgUrl;
           //                 obj.매칭상태 = '매칭완료'
           //             }
           //         }
           //         obj.상품매칭.옵션1_중국어 = option1array;
           //         obj.상품매칭.옵션2_중국어 = option2array;
           //         obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
           //     }
           // }
           // if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
           //     obj.등록이미지 = item.pic_url;
           // }
           // this.pricechange(obj);
           // obj.showspinner = false;
           // return obj
       },
       onModalShow(index, list) {
           console.log(list)
           if (this.lists[index].selectpo) {
               this.lists[index].요청내역.poarrange = true
           }
       },
       onModalShowList(item) {
           const selectedIndex = this.reqlists.findIndex(i => i._id === item._id);
           if (this.reqlists[selectedIndex].selectpo) {
               this.selectpo = this.reqlists[selectedIndex].selectpo
               this.reqlists[selectedIndex].요청내역.poarrange = true
           }
       },
       polistchange(b,list){
           console.log(b)
           list.발주수량 = b.sku수량;
           list.구매요청수량 = b.sku수량;
           list.selectpo = b.발주번호;
           this.selectpo = b.발주번호;
       },
       onModalHidden() {
           this.tableSelectedItem = ''
       },
       async reqinit(obj){
           if(obj.SKUID != ''){
               var cloneobj = {};
               if(this.$store.state.polist.length > 0){
                   this.$store.state.polist.forEach(e => {
                       if(!e.발주상태.includes('거래명세서') && !e.발주상태.includes('발주취소')){
                           e.발주세부내역.forEach(ele => {
                               if(ele.SKUID == obj.SKUID){
                                   cloneobj = this.deepClone(e);
                                   cloneobj.sku수량 = parseInt(ele.확정수량);
                                   obj.polists.push(this.deepClone(cloneobj))
                               }
                           })
                       }
                   })
               }
           }
           // if(obj.구매링크 && this.checkstock){
           //     this.lists[this.lists.length-1].showspinner = true;
           //     this.currentindex = this.lists.length-1;
           //     // var res = await this.getProductInfo(obj.구매링크)
           //     // if(res){
           //     //     this.addreqlists(res)
           //     // }
           // } else {

           // }
       },
       // async getProductInfo(구매링크){
       //     var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
       //     if(this.productinfos.find(e => e.productNo == productNo)){
       //         var value = this.productinfos.find(e => e.productNo == productNo).item;
       //         return value
       //     } else {
       //         try{
       //             var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])
       //             value = res.data.result[0].item;
       //             this.productinfos.push({
       //                 productNo : productNo,
       //                 item : value
       //             })
       //             return value
       //         }catch(e){
       //             console.log('err821' + e);
       //             return false
       //         }
       //     }
       //
       // },
       async getProductInfo(구매링크){
           var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
           try{
               var res = await axios.post('/api/aiPage/getProductInfo',[{productId:parseInt(productNo)}])
               return res
           }catch(e){
               console.log('err821' + e);
               return false
           }
       },
       showtotal(){
           this.showreqlist = false;
           setTimeout(() => {
               this.reqlists = this.reqlistsinit;
               this.showreqlist = true;
           }, 10);
       },
       filterByStatus(status) {
           this.reqlists = this.reqlistsinit.filter(e => e.진행현황 === status);
       },
       showDelete() {
           this.reqlists.forEach(item => {
               if (item.checked && item.진행현황 === '구매전') {
                   this.checkedList.push(item)
               } else {
                   alert('구매전인 상품만 삭제됩니다.')
                   return
               }
           })
           console.log(this.checkedList)
           if(confirm('삭제 후에는 복구가 불가능합니다. ' + this.checkedList.length + '건을 삭제하시겠습니까?')){
               this.deletereqlists(this.checkedList);
               this.checkedList = []
           }
       },
       showd(){
           this.showreqlist = false;
           setTimeout(() => {
               this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '입고완료');
               this.showreqlist = true;
           }, 10);
       },
       download() {
           var arr = [];
           if(_.isEmpty(this.tableSelectedItem)){
               arr = this.reqlists
           } else {
               arr = this.tableSelectedItem
           }
           function getToday(){
               var date = new Date();
               var year = date.getFullYear();
               var month = ("0" + (1 + date.getMonth())).slice(-2);
               var day = ("0" + date.getDate()).slice(-2);

               return year + month + day;
           }
           var obj = {}
           var ar = [];
           var 발주번호 = ''
           var 발주센터 = '';
           var 입고예정일 = '';
           arr.forEach(e => {
               if(e.selectpo){
                   발주번호 = e.selectpo
                   발주센터 = e.polists.find(ele => ele.발주번호 == e.selectpo).발주센터;
                   입고예정일 = e.polists.find(ele => ele.발주번호 == e.selectpo).입고예정일;
               } else {
                   발주번호 = ''
                   발주센터 = '';
                   입고예정일 = '';
               }

               obj = {
                   요청일 : this.getdate(e.요청일),
                   진행현황 : e.진행현황,
                   등록이미지 : e.등록이미지,
                   상품명 : e.상품명,
                   'SKU ID' : e.SKUID,
                   바코드 : e.바코드,
                   구매요청수량 : e.구매요청수량,
                   구매링크 : e.구매링크,
                   옵션1_중국어 : e.옵션1_중국어,
                   옵션2_중국어 : e.옵션2_중국어,
                   발주번호 : 발주번호,
                   발주센터 : 발주센터,
                   입고예정일 : 입고예정일,
               }
               ar.push(obj)
           })

           const workBook = XLSX.utils.book_new()
           const workSheet1 = XLSX.utils.json_to_sheet(ar)
           XLSX.utils.book_append_sheet(workBook, workSheet1, '구매대행요청내역')
           XLSX.writeFile(workBook, '구매대행요청내역' + '_' + getToday() + '.xlsx')
       },
       gettimestamp(date){
           return new Date(date).getTime()
       },
       search(){
           this.showreqlist = false;
           var start = this.gettimestamp(this.startdate)
           var end = this.gettimestamp(this.enddate) + (1000*60*60*24) - 1
           axios.post('/api/reqpurchase/getlists',{start:start,end:end})
               .then((res) => {
                   this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                   console.log(res.data.요청결과)

                   var arr = [];
                   res.data.reqpurchase.forEach(e => {
                       e.진행현황 = e.진행현황 ? e.진행현황 : "구매전";
                       e.진행현황 = (e.진행현황 === '배송지연예정' || e.진행현황 === '발송완료') ? '배송중' : e.진행현황;
                       e.checked = false;
                       arr.push(this.deepClone(e))
                   })
                   console.log(arr)
                   this.구매전 = arr.filter(e => e.진행현황 === '구매전').length;
                   this.구매확인 = arr.filter(e => e.진행현황 === '구매확인').length;
                   this.발송전 = arr.filter(e => e.진행현황 === '발송전').length;
                   this.배송중 = arr.filter(e => e.진행현황 === '배송중').length;
                   this.발송지연 = arr.filter(e => e.진행현황 === '발송지연').length;
                   this.입고완료 = arr.filter(e => e.진행현황 === '입고완료').length;
                   this.배송지연 = arr.filter(e => e.진행현황 === '배송지연').length;
                   this.배송완료 = arr.filter(e => e.진행현황 === '배송완료').length;
                   this.입고지연 = arr.filter(e => e.진행현황 === '입고지연').length;
                   this.입고완료 = arr.filter(e => e.진행현황 === '입고완료').length;
                   this.입고수량오류 = arr.filter(e => e.진행현황 === '입고수량오류').length;
                   this.입고상품오류 = arr.filter(e => e.진행현황 === '입고상품오류').length;
                   this.오류입고처리 = arr.filter(e => e.진행현황 === '오류입고처리').length;
                   this.오류반품처리 = arr.filter(e => e.진행현황 === '오류반품처리').length;
                   this.오류폐기처리 = arr.filter(e => e.진행현황 === '오류폐기처리').length;
                   this.오류입고대기 = arr.filter(e => e.진행현황 === '오류입고대기').length;
                   this.반품폐기완료 = arr.filter(e => e.진행현황 === '반품폐기완료').length;
                   //    this.배송지연예정 = arr.filter(e => e.진행현황 === '배송지연예정').length;
                   //    this.구매확정 = arr.filter(e => e.진행현황 === '구매확정').length;
                   this.total = this.구매전 +
                       this.구매확인 +
                       this.발송전 +
                       this.배송중 +
                       this.발송지연 +
                       this.입고완료 +
                       this.배송지연 +
                       this.배송완료 +
                       this.입고지연 +
                       this.입고완료 +
                       this.입고수량오류 +
                       this.입고상품오류 +
                       this.오류입고처리 +
                       this.오류반품처리 +
                       this.오류폐기처리 +
                       this.오류입고대기 +
                       this.반품폐기완료;


                   this.reqlistsinit = arr;
                   this.reqlists = arr;

                   this.showreqlist = true;
               })
               .catch(e => console.log('err821' + e))
       },
       getdate(timestamp){
           var date = new Date(timestamp);
           var year = date.getFullYear();
           var month = ("0" + (1 + date.getMonth())).slice(-2);
           var day = ("0" + date.getDate()).slice(-2);

           return year + "-" + month + "-" + day;
       },
       changedate(){
           var now
           var target
           if(this.selectdate == 'today'){
               this.startdate = this.getTodaywith();
               this.enddate = this.getTodaywith();
           } else {
               if(this.selectdate == 'week'){
                   now = new Date(this.getTodaywith());
                   target = now - 1000*60*60*24*7

                   this.startdate = this.getdate(target);
                   this.enddate = this.getTodaywith();
               } else {
                   if(this.selectdate == 'month'){
                   now = new Date(this.getTodaywith());
                   target = now - 1000*60*60*24*30
                   this.startdate = this.getdate(target);
                   this.enddate = this.getTodaywith();
                   }
               }
           }
       },
       allcheck(){
           if(this.allcheckreqlists){
               this.reqlists.forEach(e => {
                   e.checked = true;
               })
           } else {
               this.reqlists.forEach(e => {
                   e.checked = false;
               })
           }
       },
       confirmlists(req){
           var arr = [];
           if(this.lists.filter(e => e.checked).length == 0){
               arr = this.lists
               this.lists.forEach(e => {e.checked = true})
           } else {
               arr = this.lists.filter(e => e.checked)
           }
           if(!req){
               if(arr.filter(e => !e.상품명).length > 0){
                   alert('상품명을 확인해 주세요')
                   return []
               }

               if(arr.filter(e => e.구매링크 === undefined).length > 0){
                   alert('구매링크를 모두 입력해 주세요')
                   return []
               }
               if(arr.filter(e => e.옵션1_중국어 === undefined || e.옵션2_중국어 === undefined).length > 0){
                   if(!confirm('매칭이 안된 옵션이 있습니다. 이대로 진행하시겠습니까?')){
                       return []
                   }
               }
               if(arr.filter(e => e.요청사항 !== '등록완료').length > 0){
                   alert('요청사항이 추가되지 않은 항목이 있습니다. \'요청등록\'버튼을 눌러 요청을 등록해 주세요.')
                   return []
               }
           }

           if(arr.filter(e => !e.요청내역.barcode && !e.요청내역.sticker && !e.요청내역.stamp && !e.요청내역.tag).length > 0){
               if(!confirm('원산지 작업내용이 없는 경우 통관이 불가합니다. 상품에 이미 원산지작업이 되어 있습니까?')){
                   return []
               }
           }
           if(arr.filter(e => !e.신고영문명).length > 0){
               alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
               return []
           }
           if(arr.filter(e => !e.신고가격).length > 0){
               alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
               return []
           }
           if(arr.filter(e => !e.HSCODE).length > 0){
               alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
               return []
           }
           if(arr.filter(e => e.HSCODE.length !== 12).length > 0){
               alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
               return []
           }
           if(arr.filter(e => parseInt(e.구매재고) <  e.구매요청수량).length > 0){
               if(!confirm("구매요청수량보다 구매할수 있는 재고가 적은 상품이 있습니다. 그래도 구매요청 하시겠습니까?")){
                   return []
               }
           }

           var pass = false;
           for(var e of arr){
                if(this.reqlists.find(f => f.진행현황 == '구매전' && f.구매링크 == e.구매링크 && f.옵션1_중국어 == e.옵션1_중국어 && f.옵션2_중국어 == e.옵션2_중국어)){
                    pass = true;
                }
           }
           if(pass){
                if(!confirm("동일한 제품이 구매전인 구매대행요청내역이 존재합니다. 동일주문일 경우 해당 요청내역에서 수량등의 정보를 변경하실 수 있습니다. 그래도 추가 요청하시겠습니까?")){
                   return []
               }
           }

           // var a = this.checkDuplicate(arr, '바코드', 'selectpo');
           // if(a.duplicates){
           //     alert(a.selectpo + "발주서의 " + a.바코드 + "제품이 중복으로 구매요청 되었습니다.")
           //     return []
           // }
           return arr
       },
       checkDuplicate(arr, keyA, keyB) {
           var duplicates = false;
           var 바코드 = '';
           var selectpo = '';
           var values = {};

           for (var i = 0; i < arr.length; i++) {
               var obj = arr[i];
               var valueA = obj[keyA];
               var valueB = obj[keyB];
               var key = valueA + '-' + valueB;

               if (values[key]) {
                   바코드 = valueA;
                   selectpo = valueB;
                   duplicates = true;
                   break;
               }

               values[key] = true;
           }

           return {duplicates:duplicates,바코드:바코드,selectpo:selectpo};
       },
       confirmlist(list){
           if(!list.요청내역.barcode && !list.요청내역.sticker && !list.요청내역.stamp && !list.요청내역.tag){
               if(!confirm('원산지 작업내용이 없는 경우 통관이 불가합니다. 상품에 이미 원산지작업이 되어 있습니까?')){
                   return false
               }
           }
           if(!list.신고영문명){
               alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
               return false
           }
           if(!list.신고가격){
               alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
               return false
           }
           if(!list.HSCODE){
               alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
               return false
           }
           if(list.HSCODE.length !== 12){
               alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
               return false
           }
           if(list.구매요청수량 == 0 || !list.구매요청수량){
               alert("구매요청수량을 작성해 주세요.")
               return false
           }
           if(list.판매구성수량 == 0 || !list.판매구성수량){
               alert("판매구성수량을 작성해 주세요.")
               return false
           }

           return true
       },
       async requestpurchase(){
           this.change = true;
           var list = await this.confirmlists(false)
           if(list.length == 0){
               alert('요청할 목록이 없습니다.')
               return
           }
           if(confirm('현재 요청하신 정보를 상품DB에 저장하시겠습니까?')){
               await this.saveproductdb(false)
           }
           var cnt = list.length;
           var arr = [];
           var obj = {};
           var now = Date.now();
           list.forEach(e => {
               obj = this.deepClone(e);
               obj.companyid = this.$store.state.user.companyid;
               obj.요청일 = now;
               obj.구매_id = '';
               obj.바코드 = obj.바코드.toString().trim();
               arr.push(this.deepClone(obj));
           })
           if(confirm("총 " + cnt + "건을 구매대행 요청하시겠습니까?")){
               try{
                   var res = await axios.post('/api/reqpurchase/requestpurchase',arr);
                   this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                   alert(res.data.요청결과)
                   if(this.lists.filter(e => !e.checked).length == this.lists.length){
                       this.lists = [];
                   } else {
                       var ar = this.lists.filter(e => !e.checked)
                       this.lists = ar;
                   }
                   this.savereqlist()
               }catch(err){
                   console.log(err)
               }
           }
       },
       async saveproductdb(bool){
           this.change = true;
           var list = await this.confirmlists(false)
           if(list.length == 0){
               alert('업데이트할 목록이 없습니다.')
               return
           }
           // this.savedbstatus = true;
           var arr = list.filter(e => e.SKUID != '')
           console.log(list)
           axios.post('/api/reqpurchase/updateproductdb',arr)
           .then((res) => {
               this.$store.commit('productdbupdate',res.data.productdb);
               if(bool){
                alert(res.data.요청결과)
               }
           })
           .catch(console.log)
       },
       deleteindexfromarray(array,index){
           return array.slice(0,index).concat(array.slice(index+1))
       },
       deleteitem(index){
           this.change = true;
           this.lists = this.deleteindexfromarray(this.lists,index);
       },
       deletecheckitem(){
           this.change = true;
           var arr = [];
           this.lists.forEach(e => {
               if(!e.checked){
                   arr.push(e)
               }
           })
           this.lists = arr;
       },
       confirmexcelDownFunc(){
           if(this.reqtype == '로켓배송'){
               this.excelDownFunc()
               return
           }
           if(this.reqtype == '제트배송'){
               this.excelDownFunc()
               return
           }
           if(this.reqtype == '일반LCL'){
               if(confirm('일반LCL에서 SKUID는 10000000 이하의 숫자로 임의로 기입하시면 됩니다.')){
                   this.excelDownFunc()
                   return
               }
           }
       },
       modalreq(index){
           return 'modalreq' + index;
       },
       reset(list){
           this.change = true;
           if(!list.SKUID){
               list.상품명 = '';
           }
           list.옵션1_중국어 = undefined;
           list.옵션2_중국어 = undefined;
           list.상품매칭.옵션1_중국어 = [];
           list.상품매칭.옵션2_중국어 = [];
           list.구매링크 = undefined;
       },
       rematching(list,index){
           this.change = true;
           if(!list.구매링크){
               alert('구매링크를 입력해 주세요')
               return
           }
           list.옵션1_중국어 = undefined;
           list.옵션2_중국어 = undefined;
           list.상품매칭.옵션1_중국어 = [];
           list.상품매칭.옵션2_중국어 = [];
           this.matchoption1(index,list.구매링크)
       },
       fileupload(){
           this.$refs.pdbupload.value = '';
           this.$refs.pdbupload.click();
       },
       option1matching(a,list){
           this.change = true;
           // this.showlist = false;
           if(a.imageUrl){
               if(!list.등록이미지){
                   list.등록이미지 = a.imageUrl;
               }
               list.옵션이미지 = a.imageUrl;
           }
           list.옵션1_중국어 = a.name;


           // if(this.checkstock){
           if(list.옵션2_중국어 == ''){
               // var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
               // list.구매재고 = parseInt(list.item.productSaleInfo.find(e => e.skuAttributes[0].옵션1_중국어 === list.옵션1_중국어).amountOnSale);
               list.item.productSkuInfos.forEach(e => {
                   if (e.skuAttributes[0].value === list.옵션1_중국어) {
                       list.구매재고 = e.amountOnSale
                   }
               })
               if(list.구매재고 == 0){
                   alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
               } else {
                   alert('구매 가능한 재고수량 : ' + list.구매재고)
                   list.매칭상태 = '매칭완료';
               }
           } else {
               if(list.옵션2_중국어 !== undefined){
                   // properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                   // var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                   // list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                   // list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
                   list.item.productSkuInfos.forEach(e => {
                       if (e.skuAttributes[0].value === list.옵션1_중국어 &&
                           e.skuAttributes[1].value === list.옵션2_중국어)
                       {
                           list.구매재고 = e.amountOnSale
                       }
                   })
                   if(list.구매재고 == 0){
                       alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                   } else {
                       alert('구매 가능한 재고수량 : ' + list.구매재고)
                       list.매칭상태 = '매칭완료';
                   }
               } else {
                   list.상품매칭.옵션2_중국어 = []
                   list.item.productSkuInfos.forEach(e => {
                       if (e.skuAttributes[0].value === list.옵션1_중국어) {
                           list.상품매칭.옵션2_중국어.push({
                               name: e.skuAttributes[1].value,
                               imageUrl: e.skuAttributes[1].skuImageUrl ? e.skuAttributes[1].skuImageUrl : '',
                           })
                       }
                   })
               }
           }
           // this.showlist = true;
           // }
           this.pricechange(list)
       },
       option2matching(a,list){
           this.change = true;
           // this.showlist = false;
           if(a.imageUrl){
               list.등록이미지 = a.imageUrl;
           }
           list.옵션2_중국어 = a.name;

           // if(this.checkstock){

           // if(list.상품매칭.옵션1_중국어 && list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어)){
           //     var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
           //     var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
           //     list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
           //     list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
           //     if(list.구매재고 == 0){
           //         alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
           //     } else {
           //         alert('구매 가능한 재고수량 : ' + list.구매재고)
           //     }
           //     list.매칭상태 = '매칭완료'
           // }
           //
           // // }
           // if(list.등록이미지 == '' && list.옵션이미지 == ''){
           //     list.등록이미지 = list.item.pic_url;
           //     list.옵션이미지 = list.item.pic_url;
           // }
           if (list.옵션1_중국어 !== undefined) {
               list.item.productSkuInfos.forEach(e => {
                   if (e.skuAttributes[0].value === list.옵션1_중국어 &&
                       e.skuAttributes[1].value === list.옵션2_중국어)
                   {
                       list.구매재고 = e.amountOnSale
                   }
               })
               if(list.구매재고 == 0){
                   alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
               } else {
                   alert('구매 가능한 재고수량 : ' + list.구매재고)
                   list.매칭상태 = '매칭완료';
               }
           } else {
               list.상품매칭.옵션1_중국어 = []
               list.item.productSkuInfos.forEach(e => {
                   if (e.skuAttributes[1].value === list.옵션2_중국어) {
                       list.상품매칭.옵션1_중국어.push({
                           name: e.skuAttributes[0].value,
                           imageUrl: e.skuAttributes[0].skuImageUrl ? e.skuAttributes[0].skuImageUrl : '',
                       })
                   }
               })
           }
           // this.showlist = true;
           this.pricechange(list)
       },
       sendmessage(url){
           window.postMessage({greeting: "importproductinfo",url:url},"*",)
       },
       async matchoption1(index,구매링크){
          this.change = true;
           this.lists[index].showspinner = true;
           this.lists[index].구매링크 = 구매링크;
           this.currentindex = index;
           console.log(구매링크)
           // setTimeout(() => {this.sendmessage(구매링크);},1)
           var newObj = await this.confirmreq(this.lists[index]);
           // console.log(newObj)
           // this.showlist = false
           this.lists[index] = newObj;
           console.log(this.lists[this.currentindex])
           // this.showlist = true


           // axios.post('/api/reqpurchase/geturlinfo',{
           //     url : 구매링크
           // })
           // .then((res) => {
           //     if(res.data.요청결과 == 'success'){
           //         console.log(res.data.result)
           //         var obj = {}
           //         res.data.result.option1array.forEach(e => {
           //             obj = {
           //                 image : e.imageUrl,
           //                 optionname : e.name,
           //             }
           //             list.상품매칭.옵션1_중국어.push(obj)
           //         })
           //         res.data.result.option2array.forEach(e => {
           //             obj = {
           //                 image : e.imageUrl,
           //                 optionname : e.name,
           //             }
           //             list.상품매칭.옵션1_중국어.push(obj)
           //         })

           //     } else {
           //         alert('error777 :' + res.data.요청결과)
           //     }
           // })
           // .catch(console.log)
       },
       matchclass(a){
           if(a == '매칭완료'){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       reqclass(a){
           if(a == '등록완료'){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       excelDownFunc(){

           var arr = [];
           var myHeader = ["바코드","구매요청수량","발주번호","요청사항"];

           var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

           const range = XLSX.utils.decode_range(dataWS['!ref']);
           range.e['c'] = myHeader.length - 1;
           dataWS['!ref'] = XLSX.utils.encode_range(range);

           var wb = XLSX.utils.book_new();

           XLSX.utils.book_append_sheet(wb, dataWS, '구매대행요청');
           // 엑셀 파일을 내보낸다.
           XLSX.writeFile(wb, '구매대행요청양식_' + this.getToday() + '.xlsx');
       },
       uploadfile(event) {
           this.showuploadspinner = true;
           this.uploadstatus = '업로드 중...'
           // var obj = {};
           // var ob = {};
           // var op1 = '';
           // var op2 = '';
           // this.showlist = false;
           // this.lists = [];
           const file = event.target.files[0];
           let reader = new FileReader();
           reader.onload = (e) => {
               // let data = reader.result;
               let data = e.target.result;
               let workbook = XLSX.read(data, {type: 'binary'});
               let sheetName = workbook.SheetNames[0];
               const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
               console.log(roa)
               this.uploadreqfile(roa);
           };
           reader.readAsBinaryString(file);

       },
       modalID(id) {
           // console.log(index);
           return 'modal' + id
       },
       downloadxlsx(lists){
           if(lists.length > 0){
               var createpo = [];
               var totalpo = [];
               //this.lists, setting값에 따라 createpo 업데이트
               var obj = {};
               var link
               var option1
               var option2
               lists.forEach(e => {
                   e.발주세부내역.forEach(ele => {
                       link = '';
                       option1 = '';
                       option2 = '';
                       this.$store.state.productdb.some(element => {
                           if(element.바코드 == ele.바코드){
                               link = element.구매링크;
                               option1 = element.옵션1_중국어;
                               option2 = element.옵션2_중국어;
                               return (element.바코드 == ele.바코드)
                           }
                       })
                       if(ele.구매필요수량>0){
                           if(link == ''){
                               alert('"' + ele.바코드 + '" 제품의 구매링크 정보가 없습니다.')
                           } else {
                               obj = {
                                   '订单号':e.발주번호,
                                   '商品名称':ele.상품명,
                                   '商品数量(件)':ele.구매필요수량,
                                   '商品规格1（如：颜色）': option1,
                                   '商品规格2（如：尺码）': option2,
                                   '收件人-姓名': this.CreatePo_수취인,
                                   '收件人-手机': this.CreatePo_연락처,
                                   '收件人-省':this.CreatePo_주소_성,
                                   '收件人-市':this.CreatePo_주소_시,
                                   '收件人-区':this.CreatePo_주소_구,
                                   '收件人-详细地址':this.CreatePo_상세주소,
                                   '收货地址（省/市/区/详细地址合并的收货地址）':'',
                                   '买家留言':ele.SKUID,
                                   '1688商品链接/1688商品id':link.slice(30,link.search('.html')),
                                   '分销or现货（默认或者填1走分销，0走现货）':0,
                               }
                               totalpo.push(obj);
                           }
                       }
                   })
               })

               //1688id순으로 정렬 (중국내배송비최소화)
               totalpo.sort(function(a,b){
                   if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                       return -1;
                   }
                   if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                       return 1;
                   }
                   return 0;
               })

               //주문번호 변경 (발주번호 --> timestamp0001)
               var matchpo = [];
               var matchobj = {};
               var ponum = '';

               var now = Date.now()
               var n = 0;
               totalpo.forEach((e,i) => {
                   matchobj = {};
                   if(i > 0){
                       if(totalpo[i-1]['1688商品链接/1688商品id'] != totalpo[i]['1688商品链接/1688商品id']){
                           n++
                       }
                   }
                   ponum = now.toString() + (n+1).toString().padStart(4,'0')
                   matchobj = {
                       쿠플러스주문번호: ponum,
                       발주번호: e['订单号'],
                       SKUID: e['买家留言'],
                       상품명: e['商品名称'],
                       구매수량: e['商品数量(件)'],
                       상품번호: e['1688商品链接/1688商品id'],
                       option1: e['商品规格1（如：颜色）'],
                       option2: e['商品规格2（如：尺码）']
                   };
                   matchpo.push(matchobj);
                   e['订单号'] = ponum;
               })
               //axios matchpo

               axios.post('/api/addpurchase/addmatchpo',matchpo)
               .then((res) => {
                   this.$store.commit('matchpoupdate',res.data.matchpo);
                   alert(res.data.요청결과)
               })
               .catch(console.log)


               //1688po를 100개씩 잘라서 createpo array에 저장
               console.log(totalpo)
               var i
               var j
               var cnt = totalpo.length;

               for(j=0;j<cnt;j++){
                   if(totalpo.length < 100){
                       createpo.push(totalpo)
                       break
                   }
                   for(i=100;i>0;i--){
                       if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                           console.log(totalpo.slice(0,i))
                           createpo.push(totalpo.slice(0,i))
                           totalpo = totalpo.slice(i,totalpo.length)
                           console.log(totalpo)
                           break
                       } else {
                           if(i==1){
                               createpo.push(totalpo.slice(0,100))
                               totalpo = totalpo.slice(100,totalpo.length)
                           }
                       }
                   }
               }

               //test
               // const workBook = XLSX.utils.book_new()
               // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
               // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
               // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
               // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
               // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


               //다운로드
               for(i=0;i<createpo.length;i++){
                   const workBook = XLSX.utils.book_new()
                   const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                   XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                   XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
               }
           } else {
               alert('생성할 주문서가 없습니다.')
           }
       },
       downloadtotal(){
           //this.lists를 1688주문서로 변환해서 다운로드
           this.downloadxlsx(this.lists);
       },
       downloadselect(){
           var selectlists = [];
           this.lists.forEach(e => {
               if(e.checked == true){
                   selectlists.push(e)
               }
           })
           this.downloadxlsx(selectlists);
       },
       deepClone(obj) {
           if (obj === null || typeof obj !== "object") {
               return obj
           }

           const result = Array.isArray(obj) ? [] : {}

           for (let key of Object.keys(obj)) {
               result[key] = this.deepClone(obj[key])
           }

           return result
       },
       deletechecklist(){
           var newarr = [];
           var delarr = [];
           this.reqlists.forEach(e => {
               if(!e.checked){
                   newarr.push(this.deepClone(e))
               } else {
                   delarr.push(this.deepClone(e))
               }
           })
           this.reqlists = newarr;
           if(confirm('삭제 후에는 복구가 불가능합니다. ' + delarr.length + '건을 삭제하시겠습니까?')){
               this.deletereqlists(delarr);
           }
       },
       openModal(item) {
           this.modalData = { ...item };
           console.log('@@', this.modalData);
           console.log('@@', this.cost)
           this.$nextTick(() => {
               this.$bvModal.show('modal-table');
           });
       },
       deletelist(item){
           if(item.진행현황 !== '구매전'){
               alert('구매전인 상품만 삭제가 가능합니다.')
               return
           }
           var newarr = [];
           var delarr = [];
           this.reqlists.forEach((e) => {
               if(item._id != e._id){
                   newarr.push(this.deepClone(e))
               } else {
                   delarr.push(this.deepClone(e))
               }
           })
           this.reqlists = newarr;
           if(confirm('삭제 후에는 복구가 불가능합니다. 그래도 삭제하시겠습니까?')){
               this.deletereqlists(delarr);
           }
       },
       async deletereqlists(delarr){
           axios.post('/api/reqpurchase/deletereqpurchase',delarr)
           .then((res) => {
               if(res.data.요청결과 == '삭제완료'){
                   alert(res.data.요청결과)
                   this.search()
               } else {
                   alert(res.data.요청결과)
                   console.log(res.data)
               }
           })
           .catch(console.log)
       },
       deletedetail(발주번호,SKUID){
           var newarr = [];
           var newobj = {};
           this.lists.forEach(e => {
               if(e.발주번호 != 발주번호){
                   newarr.push(e);
               } else {
                   newobj = this.deepClone(e);
                   newobj.발주세부내역 = [];
                   e.발주세부내역.forEach(ele => {
                       if(ele.SKUID != SKUID){
                           newobj.발주세부내역.push(ele);
                       }
                   });
                   newarr.push(newobj);
               }
           })
           this.lists = newarr;
       },
       allchecklist(allchecked){
           if(allchecked == true){
               this.lists.forEach(e => {
                   e.checked = true;
               })
           } else {
               this.lists.forEach(e => {
                   e.checked = false;
               })
           }
       },
       allcheckdetaillist(allcheckdetail){
           if(allcheckdetail == true){
               this.detaillists.forEach(e => {
                   e.checked = true;
               })
           } else {
               this.detaillists.forEach(e => {
                   e.checked = false;
               })
           }
       },
       getTodaywith(){
           var date = new Date();
           var year = date.getFullYear();
           var month = ("0" + (1 + date.getMonth())).slice(-2);
           var day = ("0" + date.getDate()).slice(-2);

           return year + "-" + month + "-" + day;
       },
       getToday(){
           var date = new Date();
           var year = date.getFullYear();
           var month = ("0" + (1 + date.getMonth())).slice(-2);
           var day = ("0" + date.getDate()).slice(-2);

           return year + month + day;
       },
       timestamp(date){
           //inputdate = "yyyy-mm-dd"
           return new Date(date).getTime() / 1000
       },
       downloadpo(){
           if(this.downloadlists.length > 0){
               var createpo = [];
               var totalpo = this.downloadlists;


               //1688id순으로 정렬 (중국내배송비최소화)
               totalpo.sort(function(a,b){
                   if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                       return -1;
                   }
                   if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                       return 1;
                   }
                   return 0;
               })
               //1688id순으로 정렬 (중국내배송비최소화)
               totalpo.sort(function(a,b){
                   if (a.판매처 < b.판매처){
                       return -1;
                   }
                   if (a.판매처 > b.판매처){
                       return 1;
                   }
                   return 0;
               })


               //1688po를 100개씩 잘라서 createpo array에 저장
               console.log(totalpo)
               var i
               var j
               var cnt = totalpo.length;

               for(j=0;j<cnt;j++){
                   if(totalpo.length < 100){
                       createpo.push(totalpo)
                       break
                   }
                   for(i=100;i>0;i--){
                       if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                           console.log(totalpo.slice(0,i))
                           createpo.push(totalpo.slice(0,i))
                           totalpo = totalpo.slice(i,totalpo.length)
                           console.log(totalpo)
                           break
                       } else {
                           if(i==1){
                               createpo.push(totalpo.slice(0,100))
                               totalpo = totalpo.slice(100,totalpo.length)
                           }
                       }
                   }
               }

               //test
               // const workBook = XLSX.utils.book_new()
               // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
               // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
               // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
               // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
               // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


               //다운로드
               for(i=0;i<createpo.length;i++){
                   const workBook = XLSX.utils.book_new()
                   const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                   XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                   XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
               }
           } else {
               alert('생성할 주문서가 없습니다.')
           }
       },
       submit() {
           if(this.poinfocnt == 0){
               this.$v.$touch()
               if (this.$v.$invalid) {
                   this.lists = [];
                   this.submitStatus = true
                   var obj = {};
                   var sobj = {};
                   var ssobj = {};
                   var robj = {};
                   var addobj = {};
                   var addcnt = 0;
                   var rstockcnt = 0;
                   var stockcnt = 0;
                   var sstockcnt = 0;
                   var elcnt = 0;
                   var existaddcnt = 0;
                   var arrangecnt = 0;
                   var notarrangecnt = 0;
                   var pstockcnt = 0;
                   //setting값에 따라 lists 업데이트

                   //날짜로 this.totallists를 추려서 저장
                   this.totallists = this.$store.state.polist.filter(e => this.timestamp(this.startdate) <= this.timestamp(e.발주일시.slice(0,10)) && this.timestamp(this.enddate) >= this.timestamp(e.발주일시.slice(0,10)));
                   //this.totallists에서 lists 추출
                   this.totallists.forEach(e => {
                       e.발주세부내역.forEach(ele => {
                           arrangecnt = 0;
                           notarrangecnt = 0;
                           pstockcnt = 0;
                           rstockcnt = 0;
                           if (this.$store.state.stock.length != 0){
                               this.$store.state.stock.forEach(element => {
                                   if(element.바코드 == ele.바코드){
                                       if(element.배치발주번호 == e.발주번호){
                                           arrangecnt++
                                       } else {
                                           if(element.배치발주번호 == ''){
                                               if(element.Stockinfo == 'stock') {
                                                   if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                       notarrangecnt++
                                                   }
                                               } else {
                                                   if(element.Stockinfo == 'Pstock') {
                                                       pstockcnt++
                                                   } else {
                                                       if(element.Stockinfo == 'Rstock') {
                                                           if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                               rstockcnt++
                                                           }
                                                       }
                                                   }
                                               }
                                           }
                                       }
                                   }
                               })
                           }
                           ele.발주수량 = parseInt(ele.발주수량);
                           ele.입고수량 = parseInt(ele.입고수량);
                           if(isNaN(ele.입고수량)){
                               ele.입고수량 = 0;
                           }
                           ele.확정수량 = parseInt(ele.확정수량);
                           ele.배치수량 = arrangecnt;
                           ele.미배치재고수량 = notarrangecnt;
                           ele.구매완료수량 = pstockcnt;
                           ele.회송예정수량 = rstockcnt;
                           ele.사입재고수량 = 0;
                           if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                               this.$store.state.productdb.some(element => {
                                   if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
                                       ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
                                       return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
                                   }
                               })
                           }
                           ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
                       })
                   })



                   if(this.totallists.length == 0){
                       this.showlist = false;
                       alert('생성할 리스트가 없습니다.')
                   } else {

                       this.totallists.forEach(e => {
                           addcnt = 0;
                           rstockcnt = 0;
                           stockcnt = 0;
                           sstockcnt = 0;

                           e.발주세부내역.forEach(element => {
                               existaddcnt = addcnt;
                               addcnt += parseInt(element.발주수량);
                               this.$store.state.productdb.some(el => {
                                   if(el.바코드 == element.바코드){
                                       if(el.구매링크 == '사입'){
                                           sstockcnt += element.발주수량;
                                           ssobj = {
                                               checked : false,
                                               발주번호 : e.발주번호,
                                               발주센터 : e.발주센터,
                                               입고예정일 : e.입고예정일,
                                               발주운송 : e.발주운송,
                                               SKUID : element.SKUID,
                                               바코드 : element.바코드,
                                               상품명 : element.상품명,
                                               발주수량 : element.발주수량,
                                               매입가 : element.매입가,
                                               공급가액 : element.공급가액,
                                               부가세액 : element.부가세액,
                                           };
                                           this.sstocklists.push(ssobj);
                                           addcnt -= parseInt(element.발주수량);
                                       }
                                       return (el.바코드 == element.바코드)
                                   }
                               })
                               if(existaddcnt < addcnt){
                                   this.$store.state.stock.some(ele => {
                                       if(ele.바코드 == element.바코드){
                                           if(ele.stockinfo == 'stock'){
                                               stockcnt++
                                               if(this.stocklists.length > 0){
                                                   elcnt = 0;
                                                   this.stocklists.forEach(el => {
                                                       if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                           el.수량++
                                                           elcnt++
                                                       }
                                                   })
                                                   if(elcnt == 0){
                                                       sobj = {
                                                           checked : false,
                                                           발주번호 : e.발주번호,
                                                           발주센터 : e.발주센터,
                                                           입고예정일 : e.입고예정일,
                                                           발주운송 : e.발주운송,
                                                           SKUID : element.SKUID,
                                                           바코드 : element.바코드,
                                                           상품명 : element.상품명,
                                                           발주수량 : element.발주수량, //표에서 hide
                                                           매입가 : element.매입가,
                                                           공급가액 : element.공급가액,
                                                           부가세액 : element.부가세액,

                                                           재고박스번호 : ele.재고박스번호, //표에서 show
                                                           재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                           수량 : 1, //표에서 show
                                                       };
                                                       this.stocklists.push(sobj);
                                                   }
                                               } else {
                                                   sobj = {
                                                       checked : false,
                                                       발주번호 : e.발주번호,
                                                       발주센터 : e.발주센터,
                                                       입고예정일 : e.입고예정일,
                                                       발주운송 : e.발주운송,
                                                       SKUID : element.SKUID,
                                                       바코드 : element.바코드,
                                                       상품명 : element.상품명,
                                                       발주수량 : element.발주수량, //표에서 hide
                                                       매입가 : element.매입가,
                                                       공급가액 : element.공급가액,
                                                       부가세액 : element.부가세액,

                                                       재고박스번호 : ele.재고박스번호, //표에서 show
                                                       재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                       수량 : 1, //표에서 show
                                                   };
                                                   this.stocklists.push(sobj);
                                               }

                                               addcnt--
                                               return (addcnt == existaddcnt)
                                           } else {
                                               if(ele.stockinfo == 'Rstock'){
                                                   rstockcnt++
                                                   if(this.rstocklists.length > 0){
                                                       elcnt = 0;
                                                       this.rstocklists.forEach(el => {
                                                           if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                               el.수량++
                                                               elcnt++
                                                           }
                                                       })
                                                       if(elcnt == 0){
                                                           robj = {
                                                               checked : false,
                                                               발주번호 : e.발주번호,
                                                               발주센터 : e.발주센터,
                                                               입고예정일 : e.입고예정일,
                                                               발주운송 : e.발주운송,
                                                               SKUID : element.SKUID,
                                                               바코드 : element.바코드,
                                                               상품명 : element.상품명,
                                                               발주수량 : element.발주수량, //표에서 hide
                                                               매입가 : element.매입가,
                                                               공급가액 : element.공급가액,
                                                               부가세액 : element.부가세액,

                                                               수량 : 1 //표에서 show
                                                           };
                                                           this.rstocklists.push(robj);
                                                       }
                                                   } else {
                                                       robj = {
                                                           checked : false,
                                                           발주번호 : e.발주번호,
                                                           발주센터 : e.발주센터,
                                                           입고예정일 : e.입고예정일,
                                                           발주운송 : e.발주운송,
                                                           SKUID : element.SKUID,
                                                           바코드 : element.바코드,
                                                           상품명 : element.상품명,
                                                           발주수량 : element.발주수량, //표에서 hide
                                                           매입가 : element.매입가,
                                                           공급가액 : element.공급가액,
                                                           부가세액 : element.부가세액,

                                                           수량 : 1 //표에서 show
                                                       };
                                                       this.rstocklists.push(robj);
                                                   }

                                                   addcnt--
                                                   return (addcnt == existaddcnt)
                                               }
                                           }
                                       }
                                   });
                               }
                               if(addcnt > 0){
                                   addobj = {
                                       checked : false,
                                       발주번호 : e.발주번호,
                                       발주센터 : e.발주센터,
                                       입고예정일 : e.입고예정일,
                                       발주운송 : e.발주운송,
                                       SKUID : element.SKUID,
                                       바코드 : element.바코드,
                                       상품명 : element.상품명,
                                       발주수량 : element.발주수량,
                                       매입가 : element.매입가,
                                       공급가액 : element.공급가액,
                                       부가세액 : element.부가세액,

                                       수량 : addcnt,
                                   }
                                   this.addlists.push(addobj);
                               }
                           })

                           obj = {
                               checked : false,
                               발주번호 : e.발주번호,
                               발주일시 : e.발주일시,
                               입고예정일 : e.입고예정일,
                               전체수량 : e.발주수량,
                               구매필요수량 : addcnt,
                               재고수량 : stockcnt,
                               사입재고수량 : sstockcnt,
                               회송재고수량 : rstockcnt,
                               발주세부내역 : e.발주세부내역
                           }
                           this.lists.push(obj);
                           this.showlist = true;
                       })

                       //리스트
                       //발주번호 - 전체수량 x개, 필요수량 x개, 회송재고 x개, 재고 x개, 사입재고 x개, 구매링크 필요수 x개 (상품DB 업데이트 필요))
                   }
               } else {
                   this.submitStatus = false;
                   alert('주문서 생성 실패')
               }
           } else {
               alert('1688주문서를 생성하려면 Products > 상품DB등록 메뉴에서 ' + this.poinfocnt + '개의 상품DB에 대한 구매정보(구매링크,중국어옵션명)를 업데이트 해주세요')
           }
       },
       savesetting() {
           var payload = {
               companyid : this.$store.state.user.companyid,
               CreatePo_수취인 : this.CreatePo_수취인,
               CreatePo_연락처 : this.CreatePo_연락처,
               CreatePo_주소_성 : this.CreatePo_주소_성,
               CreatePo_주소_시 : this.CreatePo_주소_시,
               CreatePo_주소_구 : this.CreatePo_주소_구,
               CreatePo_상세주소 : this.CreatePo_상세주소,
               CreatePo_주문번호 : this.CreatePo_주문번호,
               CreatePo_발주기준일 : this.CreatePo_발주기준일
           };

           this.$store.commit('settingsupdate', payload)
           console.log(this.$store.state.settings)
           axios.post('/api/addpurchase/addsettings',payload)
           .then((res) => {alert(res.data.요청결과)})
       },
       handleBeforeUnload(event) {
           if(this.change === true){
               event.preventDefault(); // 기본 경고 메시지 방지 (브라우저에 맞게 동작하지 않을 수 있음)
               event.returnValue = ''; // Chrome에서 필요한 작업
           }

       }
   },
   mounted() {
       this.$nextTick(function () {
           this.getreqlists();
           this.usergrade()
       })
   },
   beforeMount() {
       window.addEventListener('beforeunload', this.handleBeforeUnload);
   },
   beforeDestroy() {
       window.removeEventListener('beforeunload', this.handleBeforeUnload);
   },
   beforeRouteLeave(to, from, next) {
       if(this.change === true){
           const confirmationMessage = '현재 작성한 구매요청내용을 저장하시겠습니까?';
           if (confirm(confirmationMessage)) {
               // 변경 사항 저장
               this.savereqlist()
               .then(() => {
                   next(); // 변경 사항이 저장되었으므로 페이지 이동
               })
               .catch((error) => {
                   // 에러 처리
                   console.error(error);
                   // 페이지 이동을 막거나 다른 동작을 수행할 수 있습니다.
               });
           } else {
               next(); // 변경 사항을 저장하지 않고 페이지 이동
           }
       } else {
           next();
       }
   }
}
</script>
<style>
.action-button {
    width: 30px;
    height: 30px;
}
.action-img {
    display: flex;
    justify-content: center;
    margin: 6px 0px;
}
th,td {
   font-size:80% !important;
}
.my-class .dropdown-menu {
transform: translate3d(0px, -30px, 0px) !important;
max-height: 100px;
width:400px;
z-index:10000;
overflow-y: auto;
}
#quill-container{
   height: 50px;
}
#quill-container .ql-editor{
   min-height: unset;
}
.name-input {
    display: flex;
}
.warning {
    font-size: 12px;
    position: relative;
    top: 6px;
    color: #e83e8c;
}
.warning2 {
    font-size: 12px;
    color: #e83e8c;
    margin-left: 20px;
}
.status {
    width: 100%;
    border: 3px solid #cccccc;
    display: flex;
    justify-content: space-between;
}
.status-area {
    width: 30%;
    padding: 20px;
}
.status-header {
    border-bottom: 1px solid #ccced2;
    text-align: center;
    margin-bottom: 12px;
}
.status-tit {
    margin-bottom: 20px;
}
.status-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.status-txt a{
    cursor: pointer;
}
.status-txt span {
    font-weight: bold;
}


</style>
